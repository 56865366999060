import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import PageTop from '../../components/title/PageTop';
import CpsImg from '../../assets/images/cps-ls1.jpg';
import './VillaRoom.css';
import Title from '../../components/title/Title';
import { MdOutlineKingBed, MdOutlineKitchen, MdOutlineBathtub, MdFormatQuote, MdOutlineMonitor,MdOutlinePool,MdOutlineIron } from "react-icons/md";
import {FaWifi, FaPaw,FaStar} from "react-icons/fa";
// import { TbAirConditioning } from "react-icons/tb";
import {GiFlowerPot, GiGlassShot} from "react-icons/gi";
import {FiUser} from "react-icons/fi";
import {BsSafe} from "react-icons/bs";
import {CgSmartHomeWashMachine} from "react-icons/cg";
import {TbBeach} from "react-icons/tb";
import VillaService from "../../services/villa.service";
import parse from 'html-react-parser'
import _ from "lodash";
import TestimonialService from "../../services/testmonial.service";
import ReservationService from '../../services/reservation.service';
import Footer from '../../components/footer/Footer';
import HairDryer from "../../assets/images/hair-dryer.svg";
import AirCondi from "../../assets/images/air-conditioning.svg";
import moment from "moment";
import Gallery from '../../components/gallery/Gallery';
import "../../../node_modules/lightgallery.js/dist/css/lightgallery.css";
import LoadingSpinner from '../../components/spinner/LoadingSpinner';

export default function VillaRoom() {
  const location = useLocation();
  const navigate = useNavigate();

  const postId = location.pathname.split("/")[2];
    const [APIData, setAPIData] = useState([]);
    const [AllImage, setAllImage] = useState();
    const [loading, setLoading] = useState(false);
    const [TestimonialData, setTestimonialData] = useState([]);
    const [status, setStatus] = useState(undefined);
    // For Reservation form Data.
    const [fname, setfName] = useState("");
    const [lname, setlName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [fromdate, setFromDate] = useState("");
    const [todate, setToDate] = useState("");
    const [adult, setAdult] = useState("");
    const [children, setChildren] = useState("");
    const [message, setMessage] = useState("");
    const [villa, setVilla] = useState("");
    const [unit_type, setUnitType] = useState("");
    const [invType, setInvType] = useState("");
    const [roomId, setRoomId] = useState("");
    const [villaId, setVillaId] = useState("");
    const [isSliderLoading, setIsSliderLoading] = useState(false);

    const resetReservation = () => {
        setfName("");
        setlName("");
        setEmail("");
        setPhone("");
        setFromDate("");
        setToDate("");
        setAdult("");
        setChildren("");
        setMessage("");
      }
    
    useEffect(() => {
           TestimonialService.getAllTestimonial().then(
                response => {
                    setTestimonialData(response.data);
                },
                error => {
                    console.log(error)
                }
              );
    }, []);
    useEffect(() => {
        setIsSliderLoading(true);
        VillaService.get(postId).then(
                response => {
                    console.log(response.data)
                    setAPIData(response.data);
                    setInvType(response.data.invtype_id);
                    setRoomId(response.data.room_id);
                    setVillaId(response.data.villa_id);
                    setIsSliderLoading(false);
                },
                error => {
                    console.log(error)
                    //setAPIData(response.data);
                    setIsSliderLoading(false);
                }
              );
    }, [AllImage]);

    const allImages = APIData.all_image;
    let tempImages = [];
    tempImages = APIData.all_image;
    if(tempImages){
        if(tempImages.length === APIData.all_image.length){
            tempImages.unshift(APIData.featured_image);
        }
    }
   
    const GROUP2 = tempImages;
     /* [
        "https://images.unsplash.com/photo-1594818898109-44704fb548f6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        "https://images.unsplash.com/photo-1594818896795-35ad7bcf3c6a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        "https://images.unsplash.com/photo-1594818896744-57eca4d47b07?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        "https://images.unsplash.com/photo-1594818898109-44704fb548f6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        "https://images.unsplash.com/photo-1594818896795-35ad7bcf3c6a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        "https://images.unsplash.com/photo-1594818896744-57eca4d47b07?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        "https://images.unsplash.com/photo-1594818898109-44704fb548f6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        "https://images.unsplash.com/photo-1594818896795-35ad7bcf3c6a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        "https://images.unsplash.com/photo-1594818896744-57eca4d47b07?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
        "https://images.unsplash.com/photo-1594818897077-aec41f55241f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1951&q=80"
      ]; */
    
    var data = {
        fname: fname,
        lname: lname,
        email: email,
        phone:phone,
        villa:APIData.villa_name,
        unit_type:APIData.room_name,
        fromdate:fromdate,
        todate:todate,
        adult:adult,
        children:children,
        message:message
      };
      
      const handleSubmit= (e) => {
        e.preventDefault();
        console.log("data",data)
        
        ReservationService.create(data)
      .then(response => {
        console.log(response.data);
        resetReservation();
        setStatus({ type: 'success' });
      })
      .catch(error => {
        console.log(error);
        setStatus({ type: 'error', error });
      });
      setTimeout(function() {
        setStatus("");
       }, 2000);
      }

      if (isSliderLoading) {
        // <LoadingSpinner /> 
         return <LoadingSpinner />;
         
       }

  return (
    <>
    <PageTop PageTitle={APIData.villa_name} PageSubTitle={APIData.room_name}/> 
        <div className='container'>
            <div className='villa_page_img_grid'>
                <div className='row g-2'>
                    <div className='col-md-6 col-sm-12'>
                        <div className="vpi_big vpi_img_box">
                            <a href=''className="btn btn-light show_all" data-bs-toggle="modal" data-bs-target="#villaGallery">Show all Photos</a>
                            <a href=''className="" data-bs-toggle="modal" data-bs-target="#villaGallery"><img src={`../upload/${APIData.featured_image}`} alt='' className='img-fluid'/></a>
                        </div>
                    </div>
                    <div className='col-md-6 col-sm-12'>
                        <div className='villa_img_grid'>
                            {APIData.all_image !=undefined && APIData.all_image.map((data,i) => {
                                     if(i>1 && i < 6){
                                    return (
                                        <div className="vpi_small vpi_img_box" key={i}><a href=''className="" data-bs-toggle="modal" data-bs-target="#villaGallery"><img src={`../upload/${data}`} alt='' className='img-fluid'/></a></div>
                                    )
                                 }
                            })} 
                        </div>
                    </div>
                                     {/* <div className="vpi_small vpi_img_box"><img src={CpsImg} alt='' className='img-fluid'/></div> */}
                </div>
            </div>
            <div className="villa_wrap">
                <div className="row">
                    <div className="col-md-8">
                        <Title
                        main={APIData.room_name}
                        subTitle={APIData.villa_name}
                        guest = {APIData.max_guest}
                        /> 
                        <ul className='pro_features my-4'>
                        {(() => {if (APIData.king_bed > 0){
                                        return (
                                            <li><MdOutlineKingBed/> <span>{APIData.king_bed} King Bed</span></li>
                                          )
                                    }
                                    })()}
                        {(() => {if (APIData.queen_bed > 0){
                                        return (
                                            <li><MdOutlineKingBed/> <span>{APIData.queen_bed} Queen Bed</span></li>
                                          )
                                    }
                                    })()}            
                            {(() => {if (APIData.sofa_bed > 0){
                                        return (
                                            <li><MdOutlineKingBed/> <span>{APIData.sofa_bed} Sofa Bed</span></li>
                                          )
                                    }
                                    })()}            
                            {(() => {if (APIData.trundle_bed > 0){
                                        return (
                                            <li><MdOutlineKingBed/> <span>{APIData.trundle_bed} Trundle Bed</span></li>
                                          )
                                    }
                                    })()}            
                            <li><MdOutlineKitchen/> <span> Fully Equipped Kitchen</span></li>
                            <li><MdOutlineBathtub/> <span>{APIData.bathroom} Bathroom</span></li>
                        </ul>
                        <div className='villa_description'>
                            <h3>Description</h3>
                            <div className='show_more'>
                             <span>{parse(APIData.description || '')}</span>
                             <span>{parse(APIData.accommodations || '')}</span>
                             </div>
                        </div>
                        <div className="d-flex gap-2 flex-wrap"> 
                                        <a href={`https://secure.webrez.com/hotel/2464/calendar/?invtype_id=${invType}`} target='_blank' className="btn btn-primary">Check Availability</a>
                                        <a href={`https://book.webrez.com/v30/#/property/2464/location/0/search?invtype_id=${invType}&inventory_id=${roomId}`} target='_blank' className="btn btn-primary">BOOK Now!</a>
                                    </div>
                        <div className='villa_amenities mt-5'>
                            <h3>Offered Amenities</h3>
                            <div className='show_more'>
                                {/* {(() => {
                                    if (APIData.id == 116479 || APIData.id == 116480){
                                        return (
                                            <li><MdOutlineKitchen/> Anilam Kitchen</li>
                                        )
                                    }
                                    
                                    return null;
                                    })()} */}
                                <ul className='amenities_list'>
                                    <li><MdOutlineKitchen/> Kitchen</li>
                                    <li><img src={AirCondi} alt=""/> Air conditioner</li>
                                    {(() => {if (APIData.id == 116477){
                                        return (
                                            <li><CgSmartHomeWashMachine/> Laundry Machine</li>
                                          )
                                    }
                                    })()}
                                    <li><MdOutlineMonitor/> TV with Cable</li>
                                    <li><FaWifi/> Free WIFI</li>
                                    {(() => {if (APIData.id == 116478 || APIData.id == 116479 || APIData.id == 116480 ||
                                    APIData.id == 116481 || APIData.id == 116482 || APIData.id == 116483 || APIData.id == 116484 ||
                                    APIData.id == 129786 || APIData.id == 129788 || APIData.id == 116485){
                                        return (
                                            <li><FaPaw/> Pet Friendly</li>
                                          )
                                    }
                                    })()}
                                    
                                    <li><img src={HairDryer} alt=""/> Hair Dryer</li>
                                    <li><MdOutlinePool/> Access to Pool</li>
                                    {(() => {if (APIData.id == 116479 || APIData.id == 116480){
                                        return (
                                            <li><GiFlowerPot/> Gardenview</li>
                                        )
                                    }else{return (
                                        <li><TbBeach/> Oceanview</li>
                                    )}
                                    })()}

                                    {(() => {if (APIData.id == 116478){
                                        return (
                                            <li><MdOutlineBathtub/> Bathtub</li>
                                        )
                                    }
                                    })()}

                                    <li><GiGlassShot/> Filtered Drinking Water</li>
                                    <li><BsSafe/> Safe</li>
                                    <li><MdOutlineIron/> Iron</li>
                                </ul> 
                            </div>
                        </div>
                        <div className='villa_map mt-5'>
                            <h3>Map</h3>
                            <div className='map_wrap'>
                                {data.villa === "La Esquina" ? <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7456.13751248509!2d-105.44241130370483!3d20.869278751746993!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842117b4f3271941%3A0xdd044e371c9f5e36!2sLa%20Esquina!5e0!3m2!1sen!2sin!4v1678540144795!5m2!1sen!2sin" width="100%" height="450"  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> : <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1864.0274515700603!2d-105.44401161918564!3d20.869837185850642!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8421167a9461f1d3%3A0x7e097f35203c369a!2sCasa%20Pata%20Salada!5e0!3m2!1sen!2sin!4v1675607914569!5m2!1sen!2sin" width="100%" height="450"  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>}
                            </div>
                        </div>
                        <div className="pt-5 mb-5 villa_reviews">
                            <div className="v_reviews mt-4">
                                <div className="col-md-12">
                                    <h3>Reviews <span><FaStar/> 5.0</span></h3>
                                </div>
                                
                                <div className="row mt-3">
                                    <div className="col-sm-6 col-lg-6">
                                        <ul className="rating_grade ps-0">
                                            <li className="d-flex align-items-center justify-content-start">
                                                <span className="me-3">Cleanliness</span>
                                                <div className="review_rating">
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                </div>
                                                <span className="ms-2">5/5</span>
                                            </li>
                                            <li className="d-flex align-items-center justify-content-start">
                                                <span className="me-3">Facilities</span>
                                                <div className="review_rating">
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                </div>
                                                <span className="ms-2">5/5</span>
                                            </li>
                                            <li className="d-flex align-items-center justify-content-start">
                                                <span className="me-3">Comfort</span>
                                                <div className="review_rating">
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                </div>
                                                <span className="ms-2">5/5</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-6 col-lg-6">
                                        <ul className="rating_grade ps-0">
                                            <li className="d-flex align-items-center justify-content-start">
                                                <span className="me-3">Location</span>
                                                <div className="review_rating">
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                </div>
                                                <span className="ms-2">5/5</span>
                                            </li>
                                            <li className="d-flex align-items-center justify-content-start">
                                                <span className="me-3">Value for Money</span>
                                                <div className="review_rating">
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                </div>
                                                <span className="ms-2">5/5</span>
                                            </li>
                                            <li className="d-flex align-items-center justify-content-start">
                                                <span className="me-3">Staff</span>
                                                <div className="review_rating">
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                </div>
                                                <span className="ms-2">5/5</span>
                                            </li>
                                        </ul>
                                    </div>
                                    
                                </div>
                                <div className="row review_list g-0">
                                {TestimonialData.map((data) => {
                                    if(data.villa_id === APIData.villa_id || data.room_id === APIData.room_id){
                                    return (
                                    <div className="col-lg-6 col-md-6" key={data.id}>
                                        <div className="revbox">
                                            <div className="rev_head mb-3">
                                                <div className="rev_by">
                                                    <div className="rev_icon"><FiUser/></div>
                                                    <div className="rev_name">
                                                        <div><b>{data.name}</b></div>
                                                        <span>{moment(data.staydate).utc().format('MMMM DD, YYYY')}</span>
                                                    </div>
                                                </div>
                                                <div className="rev_rate">
                                                    <div className="review_rating">
                                                    {_.times(data.rating, (i) => (<small key={i}><FaStar/></small>))} 
                                                    </div>
                                                    <span className="ms-2">{data.rating}/5</span>
                                                </div>
                                            </div>
                                            <div className="rev_body">
                                                <div className="rev_text">
                                                    <strong>{data.title}</strong>
                                                    <p>{parse(data.description)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                }
                                     })}
                                    {/* <div className="col-lg-6 col-md-6">
                                        <div className="revbox">
                                            <div className="rev_head mb-3">
                                                <div className="rev_by">
                                                    <div className="rev_icon"><FiUser/></div>
                                                    <div className="rev_name">
                                                        <div><b>James Mitchell </b></div>
                                                        <span>2 week ago</span>
                                                    </div>
                                                </div>
                                                <div className="rev_rate">
                                                    <div className="review_rating">
                                                        <small><FaStar/></small>
                                                        <small><FaStar/></small>
                                                        <small><FaStar/></small>
                                                        <small><FaStar/></small>
                                                        <small><FaStar/></small>
                                                    </div>
                                                    <span className="ms-2">5/5</span>
                                                </div>
                                            </div>
                                            <div className="rev_body">
                                                <div className="rev_text">
                                                    <strong>The Best.</strong>
                                                    <p>All that you read in these excellent reviews are absolutely true, so I won't rehash how awesome and relaxing it is to stay at Paloma. Johann makes this a wonderful spot. Miquel does all he can to keep you happy. Edgar throws out so...</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="revbox">
                                            <div className="rev_head mb-3">
                                                <div className="rev_by">
                                                    <div className="rev_icon"><FiUser/></div>
                                                    <div className="rev_name">
                                                        <div><b>Dtousche</b></div>
                                                        <span>2 week ago</span>
                                                    </div>
                                                </div>
                                                <div className="rev_rate">
                                                    <div className="review_rating">
                                                        <small><FaStar/></small>
                                                        <small><FaStar/></small>
                                                        <small><FaStar/></small>
                                                        <small><FaStar/></small>
                                                        <small><FaStar/></small>
                                                    </div>
                                                    <span className="ms-2">5/5</span>
                                                </div>
                                            </div>
                                            <div className="rev_body">
                                                <div className="rev_text">
                                                    <strong>LUXURIOUS Robinson Crusoe living WITHIN a Jungle!!</strong>
                                                    <p>5 nights in Paradise. Casa Paloma is "Open" air living where nature is close but at bay! The 4 of us tremendously enjoyed our 5 night stay. The owner Johann arranged for our pickup at the P.V. airport and we were greeted by Miguel and ...</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="revbox">
                                            <div className="rev_head mb-3">
                                                <div className="rev_by">
                                                    <div className="rev_icon"><FiUser/></div>
                                                    <div className="rev_name">
                                                        <div><b>Dave</b></div>
                                                        <span>2 week ago</span>
                                                    </div>
                                                </div>
                                                <div className="rev_rate">
                                                    <div className="review_rating">
                                                        <small><FaStar/></small>
                                                        <small><FaStar/></small>
                                                        <small><FaStar/></small>
                                                        <small><FaStar/></small>
                                                        <small><FaStar/></small>
                                                    </div>
                                                    <span className="ms-2">5/5</span>
                                                </div>
                                            </div>
                                            <div className="rev_body">
                                                <div className="rev_text">
                                                    <strong>Total seclusion in Sayulita</strong>
                                                    <p>I have been to Paloma many times and would not hesitate recommending this property for anyone looking for a romantic secluded vacation rental that is just minutes from Sayulita. The architecture reminds me of places...</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="mt-4 mb-3"><a href="#" className="btn btn-light">Show All</a></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 pt-5'>
                    <div className="form_wrap sticky-top">
                            <form onSubmit={e => {handleSubmit(e)}}  className="request_booking_form">
                                <h4 className="mb-4">Need more info?</h4>
                                <div className="input-daterange row gx-2">
                                    <div className="col-sm-6">
                                        <input type="text" onFocus={(e) => (e.target.type = "date")}  min={new Date().toISOString().split('T')[0]}  required className="form-control down_arrow input_icon mb-2 text-start"  placeholder="Arrive" value={fromdate} name="fromdate" onChange={e => setFromDate(e.target.value)}/>
                                    </div>
                                    <div className="col-sm-6">
                                        <input type="text" onFocus={(e) => (e.target.type = "date")}  disabled={fromdate === "" ? true: false} min={fromdate ? new Date(fromdate).toISOString().split("T")[0]: ""} required className="form-control down_arrow input_icon mb-2 text-start"  placeholder="Depart" value={todate} name="todate" onChange={e => setToDate(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="row gx-2">
                                    <div className="col-md-6">
                                        <input type="text" required maxLength= "20" className="form-control mb-2" placeholder="First Name" value={fname} name="fname" onChange={e => setfName(e.target.value)}/>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" required maxLength= "20" className="form-control mb-2" placeholder="Last Name" value={lname} name="lname" onChange={e => setlName(e.target.value)}/>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="email" required maxLength= "50" className="form-control mb-2" placeholder="Email Address" value={email} name="email" onChange={e => setEmail(e.target.value)}/>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" required pattern= "[0-9]+" minLength = "10" maxLength= "15" className="form-control mb-2" placeholder="Phone" value={phone} name="phone" onChange={e => setPhone(e.target.value)}/>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row gx-2">
                                            <div className="col-sm-6 mb-sm-2">
                                                <select required className="form-select" value={adult} name="adult" onChange={e => setAdult(e.target.value)}>
                                                    <option value="">Adults</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-6 mb-sm-2">
                                                <select  className="form-select" value={children} name="children" onChange={e => setChildren(e.target.value)}>
                                                    <option value="">Children</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <textarea required maxLength= "100" className="form-control mb-2" cols="10" rows="3" placeholder="Message" value={message} name="message" onChange={e => setMessage(e.target.value)}></textarea>
                                    </div>
                                    {/* <div className="col-md-12">
                                        <div className="form-check mb-3 mt-2">
                                            <input className="form-check-input" type="checkbox"  id="Remember"/>
                                            <label className="form-check-label" htmlFor="Remember">
                                                Remember my details for future requests
                                            </label>
                                        </div>
                                    </div> */}
                                    <div className="col-md-12">
                                        <button type="submit" className="btn btn-primary w-100">Inquire</button>
                                        {/* <div className="text-center my-1">or</div> */}
                                        {/* <a href={`https://secure.webrez.com/hotel/2464/?invtype_id=${invType}&inventory_id=${roomId}`} target='_blank' className="btn btn-primary w-100">BOOK Now!</a> */}
                                        {/* <a href={`https://book.webrez.com/v30/#/property/2464/location/0/search?invtype_id=${invType}&inventory_id=${roomId}`} target='_blank' className="btn btn-primary w-100">BOOK Now!</a> */}
                                    </div>
                                </div>
                                {status?.type === 'success' && <div class="alert alert-success mt-3" role="alert">
                        <h5>Thank you for getting in touch!</h5>
                        <p>We appreciate you contacting us. One of our colleagues will get back in touch with you soon! <b>Have a great day!</b></p>
                       </div>

}
      {status?.type === 'error' && (
        <p>Error in Form Submission please try again!!!</p>
      )}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade villaGallryModal" id="villaGallery" tabIndex="-1" aria-labelledby="villaGalleryLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div className="modal-body">
                <Gallery galleryImages={GROUP2}/>
                {/* <Gallery galleryImages={APIData.all_image}/> */}
                {/* <div id="villaGallryModal" className="carousel slide">
                    <div className="carousel-inner">
                            {APIData.all_image !=undefined && APIData.all_image.map((data,i) => {
                                    return (
                                        <div className={`carousel-item ${i == 0 ? 'active' : ''}`} key={i}><img src={`../upload/${data}`} alt='' className='img-fluid'/></div>
                                    )
                            })}
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#villaGallryModal" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#villaGallryModal" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                    </div> */}
                </div>
                </div>
            </div>
        </div>
        <Footer />
    </>
  )
}
