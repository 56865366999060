import React, { useState, useEffect, Component } from 'react'
import PageTop from './title/PageTop'

//import './Contact.css';
import SliderService from '../services/slider.service';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import Logo from '../assets/images/logo.svg';
//import './Contact.css';

import AuthService from "../services/auth.service";

import { withRouter } from '../common/with-router';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from 'axios';
import { Link, Navigate } from "react-router-dom";
var FormData = require('form-data');




export default function AddSlider() {
    const [title, setTitle] = useState("");
    const [offer_image, setOfferImage] = useState("");
    const [user, setUser] = useState("{redirect: null, userReady: false, currentUser: { username: '' }}");

    useEffect(() => {
      const currentUser = AuthService.getCurrentUser();
      if (!currentUser) {
      setUser({ redirect: "/home" });
      }  else {
        setUser({ currentUser: currentUser, userReady: true })
      }
    }, []);

    const upload = async () => {
      try {
        const formData = new FormData();
        formData.append("file", offer_image);
        const res = await axios.post("http://sayulitacasitas.com/api/upload", formData);
        return res.data;
      } catch (err) {
        console.log(err);
      }
    };

      
  
    const handleSubmit= async (e) => {
        e.preventDefault();
        const imgUrl =  await upload();
        var data = {
          title: title,
          offer_image: offer_image ? imgUrl : "",
         };
          
              SliderService.create(data)
          .then(response => {
          console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });
          }
          if (user.redirect) {
            return <Navigate to={user.redirect} />
          }
  
     

  return (
    <>
    {(user.userReady) ?
    <div>
        <PageTop PageTitle="Add Home Slider"/>
        <div className="container my-5 bg_light">
            <div className="row g-0">
                <div className="col-md-12">
                    <form onSubmit={e => {handleSubmit(e)}} className="p-3"  >
                        <h4 className="mb-4">Add Home Slider</h4>
                        <div className="row g-2">
                        <div className="col-md-6">
                              <input className="form-control mb-2" type="file" id="offer_image" name="" onChange={(e) => setOfferImage(e.target.files[0])} />
                              </div>
                             
                             <div className="col-md-12">
                             <ReactQuill style={{minHeight:"200px"}} className="form-control mb-2 editor" theme="snow" value={title} onChange={setTitle} />
                             </div>
                             <div className="col-md-12">
                             <div className='d-flex gap-3'>
                                <button type="submit" className="btn btn-primary ">Submit</button>
                                <Link to='/homelist' className="btn btn-secondary ">Cancel</Link>
                                </div>
                            </div>
                            <div className="col-md-12 my-3">
                                <div className="form-check mb-3 mt-2">
                                    <input className="form-check-input" type="checkbox"  id="Agree"/>
                                    <label className="form-check-label" htmlFor="Agree">
                                        By Submitting your messgae, you <a href="" >Agree to our privacy policy.</a>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                
            </div>
        </div>
        <div className="my-5 text-center pb-4 text-capitalize"><h2>Enjoy a superb Sayulita vacation!</h2></div>
    </div>: null}
    </>
  )
}
