import React, { useEffect, useState }  from 'react'
import Hero from '../components/hero/Hero';
import Title from '../components/title/Title';
import Welcome from '../components/welcome/Welcome';

import OwlCarousel from 'react-owl-carousel';
import '../../node_modules/owl.carousel/dist/assets/owl.carousel.css';

import { MdOutlineKingBed, MdOutlineKitchen, MdOutlineBathtub, MdFormatQuote } from "react-icons/md";
import { FiUser } from "react-icons/fi";
import { FaStar } from "react-icons/fa";
import Cps_1 from '../assets/images/cps-ls1.jpg';
import Cps_2 from '../assets/images/hp_cps.jpg';
import LE_2 from '../assets/images/hp_le.png';
import { Link, useNavigate } from 'react-router-dom';
import TestimonialService from "../services/testmonial.service";
import VillaService from "../services/villa.service";
import _ from "lodash";
import Footer from '../components/footer/Footer';
import LoadingSpinner from '../components/spinner/LoadingSpinner';

export default function Home() {
    const [TestimonialData, setTestimonialData] = useState([]);
    const [VillaData, setVillaData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [isSliderLoading, setIsSliderLoading] = useState(false);

    useEffect(() => {
           TestimonialService.getAllTestimonial().then(
                response => {
                    setTestimonialData(response.data);
                },
                error => {
                    console.log(error)
                }
              );

    }, []);
    useEffect(() => {
        VillaService.getAllVilla().then(
             response => {
                 setVillaData(response.data);
                 setLoading(false);
             },
             error => {
                 console.log(error)
             }
           );
 }, []);
 if (isSliderLoading) {
    // <LoadingSpinner /> 
     return <LoadingSpinner />;
     
   }
 if (isLoading) {
    return <LoadingSpinner />;
    //return <div className="hp_villa_intro py-5 hp_cps">.</div>;
  }
  //available = 1006;
  //navigate('/units', {state: {available}})

  const options = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,                
            stagePadding: 10,
        },
        500: {
            items: 1,
            stagePadding: 20,
        },
        600: {
            items: 2,
        },
        1000: {
            items: 3,

        }
    },
};
const owlReviews = {
    margin: 15,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,                
            stagePadding: 30,
        },
        500: {
            items: 1,
            stagePadding: 50,
        },
        600: {
            items: 2,
        },
        1000: {
            items: 2,

        }
    },
};
  return (
    <>
        <Hero/>
        <Welcome/>
        <section className='hp_villa_intro py-5 hp_cps' loading="lazy">
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='hp_villa_img'><img className='img-fluid' src={Cps_2} alt=''/></div>
                    </div>
                    <div className='col-md-6'>
                        <div className='hp_villa_info pt-4'>
                            <Title
                                main='Casitas at Casa Pata Salada'
                                subTitle='Beautiful Beachfront Bungalows'
                            />
                            <p>Filled with rustic Mexican charm and beautiful views, Casa Pata Salada is the spot for the Sayulita vacation that you have been waiting for. Located on a hillside facing the main beach in Sayulita, some of the best ocean views can be found here. There is a room for every type of traveler as the property consists of 9 studio casitas, each with its own unique style, décor, and guest capacity. All of our casitas are equipped with a full kitchen, private bathroom, and balcony. Casa Pata Salada is within walking distance of the best restaurants, bars, and shops that Sayulita has to offer. After you finish your day in town you will be refreshed as you dip into our infinity pool overlooking the ocean. Don’t wait to book your dream vacation with us!</p>
                            <Link to='/contact' className='btn btn-light'>Get In Touch!</Link>
                        </div>
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-12'>
                        <h2>Rooms & Suites at Casa Pata Salada <Link to="/units" state={{ data: 1006, page: "home" }}>View All Units</Link></h2>
                        <OwlCarousel className='hp_villa_carousel mt-4' {...options}>
                        {VillaData.map((casa) => {
                                    if(casa.villa_id === 1006){
                                    return (
                                    
                            <div className='item' key={casa.id}>
                                <div className="card">
                                <Link to={`/villas/${casa.id}`}><img src={`../upload/${casa.featured_image}`} className="card-img-top" alt="..."/></Link>
                                <div className="card-body">
                                    <h5 className="card-title">{casa.room_name}<span className="max_guest">{casa.max_guest} Guests Max</span></h5>
                                    <ul className='pro_features mt-3'>
                                    {(() => {if (casa.king_bed > 0){
                                        return (
                                            <li><MdOutlineKingBed/> <span>{casa.king_bed} King Bed</span></li>
                                          )
                                    }
                                    })()}
                        {(() => {if (casa.queen_bed > 0){
                                        return (
                                            <li><MdOutlineKingBed/> <span>{casa.queen_bed} Queen Bed</span></li>
                                          )
                                    }
                                    })()}            
                            {(() => {if (casa.sofa_bed > 0){
                                        return (
                                            <li><MdOutlineKingBed/> <span>{casa.sofa_bed} Sofa Bed</span></li>
                                          )
                                    }
                                    })()}            
                            {(() => {if (casa.trundle_bed > 0){
                                        return (
                                            <li><MdOutlineKingBed/> <span>{casa.trundle_bed} Trundle Bed</span></li>
                                          )
                                    }
                                    })()}            
                            <li><MdOutlineKitchen/> <span> Fully Equipped Kitchen</span></li>
                            <li><MdOutlineBathtub/> <span>{casa.bathroom} Bathroom</span></li>
                        
                                    </ul>
                                    {/* <Link to='/reservation' state={{ villa: casa.villa_name, unit: casa.room_name, page: "home" }} className="btn btn-primary">BOOK NOW!</Link> */}
                                    <a href={`https://book.webrez.com/v30/#/property/2464/location/0/search?invtype_id=${casa.invtype_id}&inventory_id=${casa.room_id}`} target='_blank' className="btn btn-primary">BOOK NOW!</a>
                                    <Link to={`/villas/${casa.id}`}  className="btn btn-light">View Details</Link>
                                </div>
                                </div>
                            </div>
                            )
                            }
                                  })}
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
        <section className='sec_div_section sec_vline mt-5'>
            <div className='sds_title'><h2>Enjoy a superb Sayulita vacation</h2></div>
        </section>
        <section className='hp_villa_intro py-5 hp_le bg_light'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='hp_villa_img'><img className='img-fluid' src={LE_2} alt=''/></div>
                    </div>
                    <div className='col-md-6'>
                        <div className='hp_villa_info pt-4'>
                            <Title
                                main='La Esquina'
                                subTitle='Newest Oceanfront Vacation Rental'
                            />
                            <p>Boasting the perfect mix of modernity and a Mexican feel, La Esquina is the ideal place for a delightful vacation. Consisting of 8 contemporary-styled studios and one luxurious penthouse all with dazzling ocean views, La Esquina is ideal for families, couples, and anyone looking to experience all that Sayulita has to offer. After a long day at the main beach directly in front of La Esquina, you can come back to enjoy a swim in the refreshing and spacious infinity pool. As its name in Spanish affirms, La Esquina is located on “The Corner” of Pescadores and Caracol street, which is within walking distance of all the best restaurants and shops in this tropical paradise.</p>
                            <Link to='/contact' className='btn btn-light'>Get In Touch!</Link>
                        </div>
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-12'>
                        <h2>Rooms & Suites at La Esquina <Link to="/units" state={{ data: 1007, page: "home" }}>View All Units</Link></h2>
                        <OwlCarousel className='hp_villa_carousel mt-4'  {...options}>
                        {VillaData.map((laes) => {
                                    if(laes.villa_id === 1007){
                                    return (
                                    
                            <div className='item' key={laes.id}>
                                <div className="card">
                                <Link to={`/villas/${laes.id}`}><img src={`../upload/${laes.featured_image}`} className="card-img-top" alt="..."/></Link>
                                <div className="card-body">
                                    <h5 className="card-title">{laes.room_name}<span className="max_guest">{laes.max_guest} Guests Max</span></h5>
                                    <ul className='pro_features mt-3'>
                                    {(() => {if (laes.king_bed > 0){
                                        return (
                                            <li><MdOutlineKingBed/> <span>{laes.king_bed} King Bed</span></li>
                                          )
                                    }
                                    })()}
                        {(() => {if (laes.queen_bed > 0){
                                        return (
                                            <li><MdOutlineKingBed/> <span>{laes.queen_bed} Queen Bed</span></li>
                                          )
                                    }
                                    })()}            
                            {(() => {if (laes.sofa_bed > 0){
                                        return (
                                            <li><MdOutlineKingBed/> <span>{laes.sofa_bed} Sofa Bed</span></li>
                                          )
                                    }
                                    })()}            
                            {(() => {if (laes.trundle_bed > 0){
                                        return (
                                            <li><MdOutlineKingBed/> <span>{laes.trundle_bed} Trundle Bed</span></li>
                                          )
                                    }
                                    })()}            
                            <li><MdOutlineKitchen/> <span> Fully Equipped Kitchen</span></li>
                            <li><MdOutlineBathtub/> <span>{laes.bathroom} Bathroom</span></li>
                        
                                    </ul>
                                   {/*  <Link to='/reservation' state={{ villa: laes.villa_name, unit: laes.room_name, page: "home" }} className="btn btn-primary">BOOK NOW!</Link> */}
                                   <a href={`https://book.webrez.com/v30/#/property/2464/location/0/search?invtype_id=${laes.invtype_id}&inventory_id=${laes.room_id}`} target='_blank' className="btn btn-primary">BOOK NOW!</a>
                                    <Link to={`/villas/${laes.id}`}  className="btn btn-light">View Details</Link>
                                </div>
                                </div>
                            </div>
                            )
                            }
                                  })}
                            
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
        <section className='reviews py-5'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <Title
                        main='Testimonials'
                        subTitle='Guest Reviews'
                        />
                        <OwlCarousel className='review_carousel mt-4' loop {...owlReviews}>
                        {TestimonialData.map((testi) => {
                                    return (
                            <div className='item' key={testi.id}>
                                <div className="review_box">
                                    <div className="review_text">
                                        <div className="review_for"><MdFormatQuote/> <h3>{testi.villa_name}</h3></div>
                                        <h3 className="review_title">{testi.title}</h3>
                                        <p>{testi.description}</p>
                                        <div className="rating d-flex">
                                        {_.times(testi.rating, (i) => ( <span className="rating_star"  key={i}><FaStar/></span>))} 
                                        </div>
                                        <div className="review_by">
                                            <div className="r_avatar"><FiUser/></div>
                                            <div className="r_info"><strong>{testi.name}</strong><small>{testi.place}</small></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )
                        })}
                        
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </>
  )
}
