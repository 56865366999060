import axios from 'axios';


const API_URL = 'http://sayulitacasitas.com/api/';


class SubscriberService {
  getAllSubscriber() {
    return axios.get(API_URL + 'subscribers');
  }

  get(id) {
    return axios.get(API_URL + `subscribers/${id}`);
  }

  create(data) {
    return axios.post(API_URL + 'subscribers', data);
  }

  update(id, data) {
    return axios.get(API_URL + `subscribers/${id}`, data);
  }

  delete(id) {
    return axios.get(API_URL + `subscribers/${id}`);
  }

  deleteAll() {
    return axios.get(API_URL + 'subscribers');
  }

  findByTitle(title) {
    return axios.get(API_URL + `subscribers?title=${title}`);
  }
}

  
export default new SubscriberService();
