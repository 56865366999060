import axios from 'axios';


const API_URL = 'http://sayulitacasitas.com/api/';


class VillaService {
  getAllVilla() {
    return axios.get(API_URL + 'villas');
  }

  get(id) {
    return axios.get(API_URL + `villas/${id}`);
  }

  create(data) {
    return axios.post(API_URL + 'villas', data);
  }
  /* create(formData) {
    console.log("form data val axios",Object.fromEntries(formData))
    formData = Object.fromEntries(formData);
    return axios.post(API_URL + 'villas', formData);
  } */

  update(id, data) {
    return axios.put(API_URL + `villas/${id}`, data);
  }

  delete(id) {
    return axios.get(API_URL + `villas/${id}`);
  }

  deleteAll() {
    return axios.get(API_URL + 'villas');
  }

  findByTitle(title) {
    return axios.get(API_URL + `villas?title=${title}`);
  }
}

  
export default new VillaService();
