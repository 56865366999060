import axios from 'axios';


const API_URL = 'http://sayulitacasitas.com/api/';
//const API_URL = process.env.REACT_APP_API_ENDPOINT;


class ContactinfoService {
  getAllContactinfo() {
    return axios.get(API_URL + 'contactinfos');
  }

  get(id) {
    return axios.get(API_URL + `contactinfos/${id}`);
  }

  create(data) {
    return axios.post(API_URL + 'contactinfos', data);
  }

  update(id, data) {
    return axios.put(API_URL + `contactinfos/${id}`, data);
  }

  delete(id) {
    return axios.get(API_URL + `contactinfos/${id}`);
  }

  deleteAll() {
    return axios.get(API_URL + 'contactinfos');
  }

  findByTitle(title) {
    return axios.get(API_URL + `contactinfos?title=${title}`);
  }
}

  
export default new ContactinfoService();
