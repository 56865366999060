import axios from 'axios';


const API_URL = 'http://sayulitacasitas.com/api/';


class OfferService {
  getAllOffer() {
    return axios.get(API_URL + 'offers');
  }

  get(id) {
    return axios.get(API_URL + `offers/${id}`);
  }

  create(data) {
    return axios.post(API_URL + 'offers', data);
  }
  
  update(id, data) {
    return axios.put(API_URL + `offers/${id}`, data);
  }

  delete(id) {
    return axios.get(API_URL + `offers/${id}`);
  }

  deleteAll() {
    return axios.get(API_URL + 'offers');
  }

  findByTitle(title) {
    return axios.get(API_URL + `offers?title=${title}`);
  }
}

  
export default new OfferService();
