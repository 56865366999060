import React, { useState, useEffect, Component } from 'react'
import PageTop from './title/PageTop'

//import './Contact.css';
import FaqService from '../services/faq.service';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import Logo from '../assets/images/logo.svg';
//import './Contact.css';

import AuthService from "../services/auth.service";

import { withRouter } from '../common/with-router';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from 'axios';
import { Link, Navigate } from "react-router-dom";
var FormData = require('form-data');




export default function AddFaq() {
    const [description, setDescription] = useState("");
    const [user, setUser] = useState("{redirect: null, userReady: false, currentUser: { username: '' }}");

    useEffect(() => {
      const currentUser = AuthService.getCurrentUser();
      if (!currentUser) {
      setUser({ redirect: "/home" });
      }  else {
        setUser({ currentUser: currentUser, userReady: true })
      }
    }, []);

    const handleSubmit= async (e) => {
        e.preventDefault();
        var data = {
          description: description,
         };
          
         FaqService.create(data)
          .then(response => {
          console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });
          }
          if (user.redirect) {
            return <Navigate to={user.redirect} />
          }
  
     

  return (
    <>
    {(user.userReady) ?
    <div>
        <PageTop PageTitle="Add Faq"/>
        <div className="container my-5 bg_light">
            <div className="row g-0">
                <div className="col-md-12">
                    <form onSubmit={e => {handleSubmit(e)}} className="p-3"  >
                        <h4 className="mb-4">Add Faq</h4>
                        <div className="row g-2">
                        <div className="col-md-12">
                             <ReactQuill style={{minHeight:"200px"}} className="form-control mb-2 editor" theme="snow" value={description} onChange={setDescription} />
                             </div>
                             <div className="col-md-12">
                             <div className='d-flex gap-3'>
                                <button type="submit" className="btn btn-primary ">Submit</button>
                                <Link to='/faqlist' className="btn btn-secondary ">Cancel</Link>
                                </div>
                            </div>
                            <div className="col-md-12 my-3">
                                
                            </div>
                        </div>
                    </form>
                </div>
                
            </div>
        </div>
    </div>: null}
    </>
  )
}
