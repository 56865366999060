import React, { useState, useEffect, Component } from 'react'
import PageTop from './title/PageTop'

//import './Contact.css';
import OfferService from '../services/offer.service';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import Logo from '../assets/images/logo.svg';
//import './Contact.css';

import AuthService from "../services/auth.service";

import { withRouter } from '../common/with-router';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from 'axios';
import { Navigate, Link, useNavigate } from "react-router-dom";
var FormData = require('form-data');




export default function AddOffer() {
  const [status, setStatus] = useState(undefined);
    const [room_id, setRoomId] = useState("");
    const [villa_id, setVillaId] = useState("");
    const [villa_name, setVillaName] = useState("");
    const [room_name, setRoomName] = useState("");
    const [heading, setHeading] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [bookby, setBookBy] = useState("");
    const [minstay, setMinStay] = useState("");
    const [publish, setPublish] = useState("");
    const [offer_image, setOfferImage] = useState("");
    const [offer_pre_image, setOfferPreImage] = useState("");
    const [user, setUser] = useState("{redirect: null, userReady: false, currentUser: { username: '' }}");
    
    const resetOffer = () => {
      setRoomId(""); setVillaId(""); setVillaName(""); setRoomName(""); setHeading(""); setTitle(""); 
      setDescription(""); setBookBy(""); setMinStay(""); setPublish(""); setOfferImage(""); setOfferPreImage(""); 
    }
    useEffect(() => {
      const currentUser = AuthService.getCurrentUser();
      if (!currentUser) {
      setUser({ redirect: "/home" });
      }  else {
        setUser({ currentUser: currentUser, userReady: true })
      }
    }, []);

    const upload = async () => {
      try {
        const formData = new FormData();
        formData.append("file", offer_image);
        const res = await axios.post("http://sayulitacasitas.com/api/upload", formData);
        return res.data;
      } catch (err) {
        console.log(err);
      }
    };

    function handleChange(e) {
      console.log(e.target.files);
      setOfferImage(e.target.files[0])
      setOfferPreImage(URL.createObjectURL(e.target.files[0]));
  }
  
    const handleSubmit= async (e) => {
        e.preventDefault();
        const imgUrl =  await upload();
        var data = {
          room_id: room_id,
          villa_id: villa_id,
          villa_name: villa_name,
          room_name:room_name,
          heading: heading,
          title: title,
          description: description,
          bookby: bookby,
          minstay:minstay,
          publish: publish,
          //offer_image:offer_image,
          offer_image: offer_image ? imgUrl : "",
         };
          
              OfferService.create(data)
          .then(response => {
          console.log(response.data);
          resetOffer();
          setStatus({ type: 'success' });
          })
          .catch(e => {
            console.log(e);
            setStatus({ type: 'error', e });
          });
          setTimeout(function() {
            setStatus("");
            window.location.href = "/offerlist";
           }, 2000);
          }
          if (user.redirect) {
            return <Navigate to={user.redirect} />
          }
  
     

  return (
    <>
    {(user.userReady) ?
    <div>
        <PageTop PageTitle="Add Offer"/>
        <div className="container my-5 bg_light">
            <div className="row g-0">
                <div className="col-md-12 mx-auto">
                    <form onSubmit={e => {handleSubmit(e)}} className="py-3 px-3"  >
                        <h4 className="mb-4 mt-4">Add Offer</h4>
                        <div className="row g-2">
                        <div className="col-md-4">
                        <select required className="form-select mb-2" value={villa_id} name="villa_id" onChange={e =>{
                                  var selectedOption = e.target.selectedOptions[0];
                                  setVillaId(selectedOption.value)
                                  setVillaName(selectedOption.text)
                               }}>
                                    <option value="" disabled>Property Name</option>
                                    <option value="1006">Casa Pata Salada</option>
                                    <option value="1007">La Esquina</option>
                                </select> 
                         </div>
                         <div className="col-md-4">
                          
                         {villa_id ==="1006" ? (
         <select required className="form-select mb-2" value={room_id} name="room_id" onChange={e =>{
          var selectedOption = e.target.selectedOptions[0];
          setRoomId(selectedOption.value)
          setRoomName(selectedOption.text)
       }}>
            <option value="">Unit Name</option>
            <option value="116478">Casita #1 Ocean View</option>
            <option value="116479">Casita #2 Garden View</option>
            <option value="116480">Casita #3 Garden View</option>
            <option value="116481">Casita #4 Ocean View</option>
            <option value="116482">Casita #5 Ocean View</option>
            <option value="116483">Casita #6 Ocean View</option>
            <option value="116484">Casita #7 Ocean View</option>
            <option value="129786">Casita #8 Ocean View</option>
            <option value="129788">Casita #9 Ocean View</option>
        </select> 
      ) : (
        <select required className="form-select mb-2" value={room_id} name="room_id" onChange={e =>{
          var selectedOption = e.target.selectedOptions[0];
          setRoomId(selectedOption.value)
          setRoomName(selectedOption.text)
       }}>
           <option value="" >Unit Name</option>
            <option value="116469">LARGE LE Room (LE2)</option>
            <option value="116470">LARGE LE Room (LE3)</option>
            <option value="116471">LARGE LE Room (LE6)</option>
            <option value="116472">LARGE LE Room (LE7)</option>
            <option value="116473">SMALL LE Room (LE1)</option>
            <option value="116474">SMALL LE Room (LE4)</option>
            <option value="116475">SMALL LE Room (LE5)</option>
            <option value="116476">SMALL LE Room (LE8)</option>
            <option value="116477">The Penthouse Room (LE)</option>
        </select> 
      )}
                             </div>
                             <div className="col-md-4">
                             <input required type="date" className="form-control down_arrow input_icon text-start"  placeholder="Book By" value={bookby} name="bookby" onChange={e => setBookBy(e.target.value)}/>
                             </div>
                             <div className="col-md-4">
                              <select required className="form-select mb-2" value={minstay} name="minstay" onChange={e => setMinStay(e.target.value)}>
                                    <option value="" disabled>Minimum Stay</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select> 
                              </div>
                              <div className="col-md-4">
                              <select required className="form-select mb-2" value={publish} name="publish" onChange={e => setPublish(e.target.value)}>
                                    <option value="" disabled>Want to Publish</option>
                                    <option value="true">True</option>
                                    <option value="false">False</option>
                                </select> 
                              </div>
                              {/* <div className="col-md-3">
                              <input required className="form-control mb-2" type="file" id="offer_image" name="" onChange={(e) => setOfferImage(e.target.files[0])} />
                              </div> */}
                              <div className="col-md-6">
                                <input required className="form-control mb-2 bg-white" type="file" id="offer_image" name="" onChange={handleChange}/>
                              </div>
                              <div className="col-md-12">
                                {offer_image && <div className="image-pr" ><img className="img-fluid" src={offer_pre_image} /></div>} 
                              </div>
                             <div className="col-md-6">
                             <input required type="text" className="form-control mb-2" placeholder="Heading" value={heading} name="heading" onChange={e => setHeading(e.target.value)}/>
                             </div>
                            <div className="col-md-6">
                             <input required type="text" className="form-control mb-2" placeholder="Title" value={title} name="title" onChange={e => setTitle(e.target.value)}/>
                             </div>
                             <div className="col-md-12">
                             <ReactQuill required style={{minHeight:"300px"}} className="form-control mb-2 editor" theme="snow" value={description} onChange={setDescription} />
                             </div>
                             <div className="col-md-12">
                                <div className='d-flex gap-3'>
                                <button type="submit" className="btn btn-primary ">Submit</button>
                                <Link to='/offerlist' className="btn btn-secondary ">Cancel</Link>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-check mt-2">
                                    {status?.type === 'success' && <p>Your information was successfully submitted!</p>}
                                    {status?.type === 'error' && (
                                      <p>Error in Form Submission please try again!!!</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                
            </div>
        </div>
       
    </div>: null}
    </>
  )
}
