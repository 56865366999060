import React, { useState, useEffect, Component } from 'react'
import PageTop from './title/PageTop'

//import './Contact.css';
import VillaService from '../services/villa.service';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import Logo from '../assets/images/logo.svg';
//import './Contact.css';

import AuthService from "../services/auth.service";

import { withRouter } from '../common/with-router';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from 'axios';
import { Link, Navigate } from "react-router-dom";
var FormData = require('form-data');




export default function AddVilla() {
    const [room_id, setRoomId] = useState("");
    const [villa_id, setVillaId] = useState("");
    const [villa_name, setVillaName] = useState("");
    const [room_name, setRoomName] = useState("");
    const [featured_image, setFeaImage] = useState(null);
    const [all_image, setAllImage] = useState([])
    const [king_bed, setKingBed] = useState("");
    const [queen_bed, setQueenBed] = useState("");
    const [sofa_bed, setSofaBed] = useState("");
    const [trundle_bed, setTrundleBed] = useState("");
    const [kitchen, setKitchen] = useState("");
    const [bathroom, setBathroom] = useState("");
    const [description, setDescription] = useState("");
    const [accommodations, setAccommodations] = useState("");
    const [user, setUser] = useState("{redirect: null, userReady: false, currentUser: { username: '' }}");
   
    useEffect(() => {
      const currentUser = AuthService.getCurrentUser();
      if (!currentUser) {
      setUser({ redirect: "/home" });
      }  else {
        setUser({ currentUser: currentUser, userReady: true })
      }
    }, []);
      
      const upload = async () => {
        try {
          const formData = new FormData();
          formData.append("file", featured_image);
          const res = await axios.post("http://localhost:8080t/upload", formData);
          return res.data;
        } catch (err) {
          console.log(err);
        }
      };

      const uploadMulti = async () => {
        try {
          const formData = new FormData();
          for (const mfile of all_image) {
            formData.append("files", mfile);
          }
          formData.append("data", JSON.stringify(all_image?.data || {}));
          console.log("formData of multiple",formData)
          const res = await axios.post("http://localhost:8080t/uploadmulti", formData);
          return res.data;
        } catch (err) {
          console.log(err);
        }
      };

    const handleSubmit= async (e) => {
        e.preventDefault();
        const imgUrlMulti = await uploadMulti();
        const imgUrl =  await upload();
       // const imgUrlMulti = "";
        

        var data = {
          room_id: room_id,
          villa_id: villa_id,
          villa_name: villa_name,
          room_name:room_name,
          /* featured_image:featured_image, */
          featured_image: featured_image ? imgUrl : "",
          all_image: all_image ? imgUrlMulti : "",
          //all_image:all_image,
          king_bed: king_bed,
          queen_bed: queen_bed,
          sofa_bed: sofa_bed,
          trundle_bed:trundle_bed,
          kitchen: kitchen,
          bathroom:bathroom,
          description: description,
          accommodations: accommodations,
          };
          /* console.log("data of file upload", data)
          if(data.villa_id === "1006"){
            data.villa_name = "Casa Pata Salada"
          }else{
            data.villa_name = "La Esquina"
          }
        
         */VillaService.create(data)
      .then(response => {
       console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
      }
      if (user.redirect) {
        return <Navigate to={user.redirect} />
      }
  
     

  return (
    <>
    {(user.userReady) ?
    <div>
        <PageTop PageTitle="Add Villa"/>
        <div className="container my-5 bg_light">
            <div className="row g-0">
                <div className="col-md-12">
                    <form onSubmit={e => {handleSubmit(e)}} className="py-3 px-3" >
                        <h4 className="mb-4">Add Villa</h4>
                        <div className="row gy-1 gx-4">
                          <div className='col-md-4'>
                            <label><small>Room ID</small></label>
                            <input type="text" className="form-control mb-2" placeholder="Room ID" value={room_id} name="room_id" onChange={e => setRoomId(e.target.value)}/>
                            
                          </div>
                          <div className='col-md-4'>
                          <label><small>Property Name</small></label>
                            <select className="form-select mb-2" value={villa_id} name="villa_id" onChange={e =>{
                              var selectedOption = e.target.selectedOptions[0];
                              setVillaId(selectedOption.value)
                              setVillaName(selectedOption.text)
                            }}>
                                <option value="0" >Property Name</option>
                                <option value="1006">Casa Pata Salada</option>
                                <option value="1007">La Esquina</option>
                            </select> 
                          </div>
                          <div className='col-md-4'>
                            <label><small>Unit Name</small></label>
                            <input type="text" className="form-control mb-2" placeholder="Unit Name" value={room_name} name="room_name" onChange={e => setRoomName(e.target.value)}/>
                          </div>
                          <div className='col-md-6'>
                            <label><small>Featured Image</small></label>
                            <input className="form-control mb-2" type="file" id="featured_image" name="" onChange={(e) => setFeaImage(e.target.files[0])} />
                            </div>
                          <div className='col-md-6'>
                            <label><small>Images</small></label>
                            <input className="form-control mb-2" type="file" id="all_image" name="" multiple onChange={(e) => { setAllImage(e.target.files) }} />
                          </div>
                          <div className='col-md-4'>
                            <label><small>No. Of King Bed</small></label>
                            <select className="form-select mb-2" value={king_bed} name="king_bed" onChange={e => setKingBed(e.target.value)}>
                                <option value="" >No. Of King Bed</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                            </select>
                          </div>
                          <div className='col-md-4'>
                            <label><small>No. Of Queen Bed</small></label>
                            <select className="form-select mb-2" value={queen_bed} name="queen_bed" onChange={e => setQueenBed(e.target.value)}>
                                <option value="" disabled>No. Of Queen Bed</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                            </select>
                          </div>
                          <div className='col-md-4'>
                            <label><small>No. Of Sofa Bed</small></label>
                            <select className="form-select mb-2" value={sofa_bed} name="sofa_bed" onChange={e => setSofaBed(e.target.value)}>
                                <option value="" disabled>No. Of Sofa Bed</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                            </select>
                          </div>
                          <div className='col-md-4'>
                            <label><small>No. Of Trundle Bed</small></label>
                            <select className="form-select mb-2" value={trundle_bed} name="trundle_bed" onChange={e => setTrundleBed(e.target.value)}>
                                <option value="" disabled>No. Of Trundle Bed</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                            </select>
                          </div>
                          <div className='col-md-4'>
                            <label><small>No. Of Kitchen</small></label>
                            <select className="form-select mb-2" value={kitchen} name="kitchen" onChange={e => setKitchen(e.target.value)}>
                                <option value="" disabled>No. Of Kitchen</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                            </select>
                          </div>
                          <div className="col-md-4">
                            <label><small>No. Of Bathroom</small></label>
                            <select className="form-select mb-2" value={bathroom} name="bathroom" onChange={e => setBathroom(e.target.value)}>
                                <option value="" disabled>No. Of Bathroom</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                            </select> 
                          </div>
                          <div className='col-md-6'>
                          <ReactQuill style={{minHeight:"300px"}} className="form-control mb-2 editor" theme="snow" value={description} onChange={setDescription} />
                          </div>
                            
                            <div className="col-md-6">
                               <ReactQuill style={{minHeight:"300px"}} className="form-control mb-2 editor" theme="snow" value={accommodations} onChange={setAccommodations} /> 
                            </div>
                            
                            <div className="col-md-12">
                              <div className='d-flex gap-3'>
                                <button type="submit" className="btn btn-primary ">Submit</button>
                                <Link to='/listvilla' className="btn btn-secondary ">Cancel</Link>
                                </div>
                            </div>
                            <div className="col-md-12 my-3">
                                <div className="form-check mb-3 mt-2">
                                    <input className="form-check-input" type="checkbox"  id="Agree"/>
                                    <label className="form-check-label" htmlFor="Agree">
                                        By Submitting your messgae, you <a href="" >Agree to our privacy policy.</a>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                
            </div>
        </div>
        <div className="my-5 text-center pb-4 text-capitalize"><h2>Enjoy a superb Sayulita vacation!</h2></div>
    </div>: null}
    </>
  )
}
