import React, { useState, useEffect, Component } from 'react'
import PageTop from './title/PageTop'

//import './Contact.css';
import VillaService from '../services/villa.service';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import Logo from '../assets/images/logo.svg';
//import './Contact.css';

import AuthService from "../services/auth.service";

import { withRouter } from '../common/with-router';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from 'axios';
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
var FormData = require('form-data');




export default function EditVilla() {
  const state = useLocation().state;
    const [id, setId] = useState(state?.id || "");
    const [room_id, setRoomId] = useState(state?.room_id || "");
    const [villa_id, setVillaId] = useState(state?.villa_id || "");
    const [villa_name, setVillaName] = useState(state?.villa_name || "");
    const [room_name, setRoomName] = useState(state?.room_name || "");
    const [featured_image, setFeaImage] = useState(null);
    const [all_image, setAllImage] = useState([])
    const [king_bed, setKingBed] = useState(state?.king_bed || "0");
    const [queen_bed, setQueenBed] = useState(state?.queen_bed || "0");
    const [sofa_bed, setSofaBed] = useState(state?.sofa_bed || "0");
    const [trundle_bed, setTrundleBed] = useState(state?.trundle_bed || "0");
    const [kitchen, setKitchen] = useState(state?.kitchen || "0");
    const [bathroom, setBathroom] = useState(state?.bathroom || "0");
    const [description, setDescription] = useState(state?.description || "");
    const [accommodations, setAccommodations] = useState(state?.accommodations || "");
    const [user, setUser] = useState("{redirect: null, userReady: false, currentUser: { username: '' }}");
    const [offer_pre_image, setOfferPreImage] = useState("");
    const [featured_image_exist, setOfferImageExist] = useState(state?.featured_image || "");
    const [all_pre_image, setAllPreImage] = useState("");
    const [all_image_exist, setAllImageExist] = useState(state?.all_image.split(',') || []);
    const [status, setStatus] = useState(undefined);

    const resetVilla = () => {
      setRoomId(""); setVillaId(""); setFeaImage(""); setAllImage(""); setKingBed(""); setQueenBed(""); 
      setSofaBed(""); setTrundleBed(""); 
      setKitchen(""); setBathroom(""); setDescription(""); setAccommodations("");  setOfferPreImage(""); 
    }
   
    const navigate = useNavigate();
    useEffect(() => {
      const currentUser = AuthService.getCurrentUser();
      if (!currentUser) {
      setUser({ redirect: "/home" });
      }  else {
        setUser({ currentUser: currentUser, userReady: true })
      }
    }, []);
      
      const upload = async () => {
        try {
          const formData = new FormData();
          formData.append("file", featured_image);
          const res = await axios.post("http://sayulitacasitas.com/api/upload", formData);
          return res.data;
        } catch (err) {
          console.log(err);
        }
      };

      const uploadMulti = async () => {
        try {
          const formData = new FormData();
          for (const mfile of all_image) {
            formData.append("files", mfile);
          }
          formData.append("data", JSON.stringify(all_image?.data || {}));
          const res = await axios.post("http://sayulitacasitas.com/api/uploadmulti", formData);
          return res.data;
        } catch (err) {
          console.log(err);
        }
      };

    const handleSubmit= async (e) => {
        e.preventDefault();
        //const imgUrlMulti = await uploadMulti();
        //const imgUrl =  await upload();
        let imgUrlMulti = "";
        let imgUrl = "";
        
        var data = {
          room_id: room_id,
          villa_id: villa_id,
          villa_name: villa_name,
          room_name:room_name,
          king_bed: king_bed,
          queen_bed: queen_bed,
          sofa_bed: sofa_bed,
          trundle_bed:trundle_bed,
          kitchen: kitchen,
          bathroom:bathroom,
          description: description,
          accommodations: accommodations,
          };

        if(featured_image_exist == null && all_image_exist == null){
           imgUrlMulti = await uploadMulti();
           imgUrl =  await upload();
           data.featured_image = featured_image ? imgUrl : "";
           data.all_image = all_image ? imgUrlMulti : "";
         } 
         if(featured_image_exist != null && all_image_exist == null){
          imgUrlMulti = await uploadMulti();
          data.featured_image = featured_image_exist; 
          data.all_image = all_image ? imgUrlMulti : "";
        } 
        if(featured_image_exist == null && all_image_exist != null){
          imgUrl =  await upload();
          data.featured_image = featured_image ? imgUrl : "";
          data.all_image = all_image_exist;
        } 

        if(featured_image_exist != null && all_image_exist != null){
          data.featured_image = featured_image_exist; 
          data.all_image = all_image_exist;
        } 
        
         
          VillaService.update(id, data)
      .then(response => {
       console.log(response.data);
       resetVilla();
          setStatus({ type: 'success' });
      })
      .catch(e => {
        console.log(e);
        setStatus({ type: 'error', e });
      });
      setTimeout(function() {
        setStatus("");
        window.location.href = "/listvilla";
       }, 2000);
      }
      if (user.redirect) {
        return <Navigate to={user.redirect} />
      }

      function handleChange(e) {
        //console.log(e.target.files);
        setFeaImage(e.target.files[0])
        setOfferPreImage(URL.createObjectURL(e.target.files[0]));
        setOfferImageExist(null);
    }

    function handleMultiChange(e) {
      let images = [];
      for (let i = 0; i < e.target.files.length; i++) {
        images.push(URL.createObjectURL(e.target.files[i]));
      }
  
      setAllImage(e.target.files);
      setAllPreImage(images);
      setAllImageExist(null);
      //console.log("all_pre_image", all_pre_image)
      /* setProgressInfos({ val: [] });
      setMessage([]); */
      /* console.log(e.target.files);
      setAllImage(e.target.files)
      setAllPreImage(URL.createObjectURL(e.target.files));
      setAllImageExist(null); */
  }
  
     

  return (
    <>
    {(user.userReady) ?
    <div>
        <PageTop PageTitle="Edit Villa"/>
        <div className="container my-5 bg_light">
            <div className="row g-0">
                <div className="col-md-12">
                    <form onSubmit={e => {handleSubmit(e)}} className="py-3 px-3">
                        <h4 className="mb-4">Edit Villa</h4>
                        <div className="row gy-1 gx-4">
                        <div className='col-md-4'>
                            <label><small>Room ID</small></label>
                            <input disabled type="text" className="form-control mb-2" placeholder="Room ID" value={room_id} name="room_id" onChange={e => setRoomId(e.target.value)}/>
                            
                          </div>
                          <div className='col-md-4'>
                          <label><small>Property Name</small></label>
                            <select disabled required className="form-select mb-2" value={villa_id} name="villa_id" onChange={e =>{
                              var selectedOption = e.target.selectedOptions[0];
                              setVillaId(selectedOption.value)
                              setVillaName(selectedOption.text)
                            }}>
                                <option value="" >Property Name</option>
                                <option value="1006">Casa Pata Salada</option>
                                <option value="1007">La Esquina</option>
                            </select> 
                          </div>
                          <div className='col-md-4'>
                            <label><small>Unit Name</small></label>
                            <input disabled type="text" className="form-control mb-2" placeholder="Unit Name" value={room_name} name="room_name" onChange={e => setRoomName(e.target.value)}/>
                          </div>
                          {/* <div className='col-md-6'>
                            <label><small>Featured Image</small></label>
                            <input className="form-control mb-2" type="file" id="featured_image" name="" onChange={(e) => setFeaImage(e.target.files[0])} />
                          </div> */}
                          <div className="col-md-6">
                          <label><small>Featured Image</small></label>
                              <input className="form-control mb-2" type="file" id="featured_image" name="" onChange={handleChange} />
                              </div>
                              <div className='col-md-6'>
                            <label><small>Images</small></label>
                            <input className="form-control mb-2" type="file" id="all_image" name="" multiple onChange={handleMultiChange}/>
                          </div>
                          {/* <div className='col-md-6'>
                            <label><small>Images</small></label>
                            <input className="form-control mb-2" type="file" id="all_image" name="" multiple onChange={(e) => { setAllImage(e.target.files) }} />
                          </div> */}
                          <div className="col-md-6">
                                {featured_image && <div className="image-pr" ><img className="img-fluid" src={offer_pre_image} /></div>} 
                                {featured_image_exist && <div className="image-pr" ><img className="img-fluid"  src={`../upload/${featured_image_exist}`} /></div>} 
                              </div>
                              <div className="col-md-6">
                                {all_pre_image && <div className="image-pr1" >
                                  {/* <img className="img-fluid" src={all_pre_image} /> */}
                                  {all_pre_image.map((data,i) => {
                                    return (
                                      <div className="image-pr-multi" key={i}><img className="img-fluid" alt="" src={`${data}`} /></div>
                                    )
                            })} 
                                  </div>
                                  } 
                                {all_image_exist && <div className="image-pr1" >
                                {all_image_exist.map((data,i) => {
                                    return (
                                      <div className="image-pr-multi" key={i}><img className="img-fluid" alt="" src={`../upload/${data}`} /></div>
                                    )
                            })} 
                                {/*   <img className="img-fluid"  src={`../upload/${all_image_exist}`} /> */}
                                  </div>} 
                              </div> 
                          <div className='col-md-3'>
                            <label><small>No. Of King Bed</small></label>
                            <select required className="form-select mb-2" value={king_bed} name="king_bed" onChange={e => setKingBed(e.target.value)}>
                            <option value="" >No. Of King Bed</option>
                                <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </select> 
                                </div>
                          <div className='col-md-3'>
                            <label><small>No. Of Queen Bed</small></label>
                           <select required className="form-select mb-2" value={queen_bed} name="queen_bed" onChange={e => setQueenBed(e.target.value)}>
                           <option value="" disabled>No. Of Queen Bed</option>
                                <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </select> 
                                </div>
                                <div className='col-md-4'>
                            <label><small>No. Of Sofa Bed</small></label>
                            <select required className="form-select mb-2" value={sofa_bed} name="sofa_bed" onChange={e => setSofaBed(e.target.value)}>
                                <option value="" disabled>No. Of Sofa Bed</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                            </select>
                          </div>
                          <div className='col-md-4'>
                            <label><small>No. Of Trundle Bed</small></label>
                            <select required className="form-select mb-2" value={trundle_bed} name="trundle_bed" onChange={e => setTrundleBed(e.target.value)}>
                                <option value="" disabled>No. Of Trundle Bed</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                            </select>
                          </div>
                          <div className='col-md-3'>
                            <label><small>No. Of Kitchen</small></label>
                            <select required className="form-select mb-2" value={kitchen} name="kitchen" onChange={e => setKitchen(e.target.value)}>
                            <option value="" disabled>No. Of Kitchen</option>
                                <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </select> 
                                </div>
                          <div className="col-md-3">
                            <label><small>No. Of Bathroom</small></label>
                            <select required className="form-select mb-2" value={bathroom} name="bathroom" onChange={e => setBathroom(e.target.value)}>
                            <option value="" disabled>No. Of Bathroom</option>
                                <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </select> 
                                </div>
                          <div className='col-md-6'>
                          <label><small>Intro Text</small></label>
                           <ReactQuill required style={{minHeight:"300px"}} className="form-control mb-2 editor" theme="snow" value={description} onChange={setDescription} />
                           </div>
                            
                            <div className="col-md-6">
                            <label><small>Description</small></label>
                              <ReactQuill required style={{minHeight:"300px"}} className="form-control mb-2 editor" theme="snow" value={accommodations} onChange={setAccommodations} /> 
                            </div>
                            
                            <div className="col-md-12">
                            <div className='d-flex gap-3'>
                                  <button type="submit" className="btn btn-primary ">Submit</button>
                                  <Link to='/listvilla' className="btn btn-secondary ">Cancel</Link>
                                </div>
                            </div>
                            <div className="col-md-12 my-3">
                            <div className="form-check mt-2">
                                    {status?.type === 'success' && <p>Your information was successfully submitted!</p>}
                                    {status?.type === 'error' && (
                                      <p>Error in Form Submission please try again!!!</p>
                                    )}
                                </div>
                            </div>
                            </div>
                    </form>
                </div>
                
            </div>
        </div>
       
    </div>: null}
    </>
  )
}
