import React from 'react';
import './Title.css';

export default function Title(props) {
  return (
    <div className='title'>
        <div className='sup_title'>{props.subTitle}</div>
        <h2>{props.main} {props.guest ? <span className="max_guest">{props.guest} Guests Max</span> : ""}</h2>
    </div>
  )
}
