import React, { useState, useEffect, Component } from 'react'
import PageTop from './title/PageTop'

//import './Contact.css';
import ContactinfoService from '../services/contactinfo.service';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import Logo from '../assets/images/logo.svg';
//import './Contact.css';

import AuthService from "../services/auth.service";

import { withRouter } from '../common/with-router';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from 'axios';
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
var FormData = require('form-data');




export default function EditContactinfo() {
  const state = useLocation().state;
    console.log("state ====== ", state)
    const [id, setId] = useState(state?.id || "");
    const [usphone, setUsphone] = useState(state?.usphone || "");
    const [mexphone, setMexphone] = useState(state?.mexphone || "");
    const [whatsapp, setWhatsapp] = useState(state?.whatsapp || "");
    const [email, setEmail] = useState(state?.email || "");
    const [officehour, setOfficehour] = useState(state?.officehour || "");
    const [user, setUser] = useState("{redirect: null, userReady: false, currentUser: { username: '' }}");
    const [status, setStatus] = useState(undefined);
    
    const navigate = useNavigate();

    useEffect(() => {
      const currentUser = AuthService.getCurrentUser();
      if (!currentUser) {
      setUser({ redirect: "/home" });
      }  else {
        setUser({ currentUser: currentUser, userReady: true })
      }
    }, []);

    
   
  const resetContactinfo = () => {
    setUsphone("");
    setMexphone("");
    setWhatsapp("");
    setWhatsapp("");
    setEmail("");
    setOfficehour("");
  }
  
    const handleSubmit= async (e) => {
        e.preventDefault();
       
        var data = {
          usphone: usphone,
          mexphone: mexphone,
          whatsapp: whatsapp,
          email: email,
          officehour: officehour,
         };
          
              ContactinfoService.update(id, data)
          .then(response => {
          console.log(response.data);
          resetContactinfo();
          setStatus({ type: 'success' });
          })
          .catch(e => {
            console.log(e);
            setStatus({ type: 'error', e });
          });
          setTimeout(function() {
            setStatus("");
           window.location.href = "/contactinfolist";
           }, 2000);
          }
          if (user.redirect) {
            return <Navigate to={user.redirect} />
          }
  
     

  return (
    <>
    {(user.userReady) ?
    <div>
        <PageTop PageTitle="Edit Contact Info"/>
        <div className="container my-5 bg_light">
            <div className="row g-0">
                <div className="col-md-12">
                    <form onSubmit={e => {handleSubmit(e)}} className="px-3 py-3" >
                        <h4 className="mb-4">Edit Contact Info</h4>
                        <div className="row g-2">
                        <div className='col-md-6'>
                            <label><small>US Phone</small></label>
                            <input required type="text" className="form-control mb-2" placeholder="US Phone" value={usphone} name="usphone" onChange={e => setUsphone(e.target.value)}/>
                            
                          </div>
                          <div className='col-md-6'>
                            <label><small>MEX Phone</small></label>
                            <input required type="text" className="form-control mb-2" placeholder="MEX Phone" value={mexphone} name="mexphone" onChange={e => setMexphone(e.target.value)}/>
                            
                          </div>
                          <div className='col-md-6'>
                            <label><small>WhatsApp</small></label>
                            <input required type="text" className="form-control mb-2" placeholder="WhatsApp" value={whatsapp} name="whatsapp" onChange={e => setWhatsapp(e.target.value)}/>
                            
                          </div>
                          <div className='col-md-6'>
                            <label><small>Email Id</small></label>
                            <input  required type="text" className="form-control mb-2" placeholder="Email Id" value={email} name="email" onChange={e => setEmail(e.target.value)}/>
                            
                          </div>
                        <div className="col-md-12">
                        <label><small>Office Hours</small></label>
                             <ReactQuill required style={{minHeight:"200px"}} className="form-control mb-2 editor" theme="snow" value={officehour} onChange={setOfficehour} />
                             </div> 
                             <div className="col-md-12">
                                <div className='d-flex gap-3'>
                                  <button type="submit" className="btn btn-primary ">Submit</button>
                                  <Link to='/contactinfolist' className="btn btn-secondary ">Cancel</Link>
                                </div>
                            </div>
                            <div className="col-md-12 my-3">
                            <div className="form-check mt-2">
                                    {status?.type === 'success' && <p>Your information was successfully submitted!</p>}
                                    {status?.type === 'error' && (
                                      <p>Error in Form Submission please try again!!!</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                
            </div>
        </div>
    </div>: null}
    </>
  )
}
