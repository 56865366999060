import React from 'react'
import PageTop from '../../components/title/PageTop'
import Footer from '../../components/footer/Footer';

export default function BookingTest() {
  return (
    <div>
        <PageTop PageTitle="Booking Test Page"/>
       <div className="container py-5">
        <div className="row">
            <div className="col-lg-8 mx-auto col-md-12">
                <div className="textpage_content py-5">
                  <h5>Booking Demo Page</h5>
                  <p className='border-bottom mb-3 pb-3'>We have 2 given below cases, so as per each case, we will show the specific button on the respective place.</p>
                  <h5>Booking Demo Button 1</h5>
                  <p  className='border-bottom mb-3 pb-3'>Right now If on Home Page, the end user enters the Start date, End date, Adult Count and Children Count and clicks on the Find a Room Button then we redirect the end user to another page, and show the listing of available villas. so on that villa list page, we will show the given below button so the end user will able to book that specific villa with entered prefill details. 
                  <br/> 
                  <br/>for Ex., on that page end user clicked on the Casita #2 Garden View Book Now Button. so we will redirect the end user to the given below page.
                  <br/> 
                  <br/>
                               <a href="https://secure.webrez.com/Bookings105/activity-edit.html?hotel_id=2464&table=transactions&listing_id=-1&invtype_id=33925&package_id=156151&date_from=20230421&date_to=20230425&num_children=0&num_adults=2&num_babies=0&mode=edit&sourcecode_id=&reservationcode_id=&access_code=&guest_password=&guest_email=&version=2&" className="btn btn-primary ">Book Now</a>
                           
                            </p>
                  <h5>Booking Demo Button 2</h5>
                  <p  className='border-bottom mb-3 pb-3'>If the End user clicks on any, book now button, in that case, we don't have any details so we just redirect the end user to the given below page for the selection of the Start date, End date, Adult and Children Count.
                  <br/> 
                  <br/>
                  for Ex., end user clicked on the Casita #2 Garden View Book Now Button.
                  <br/> 
                  <br/>
                  <a href="https://book.webrez.com/v30/#/property/2464/location/1006/search?invtype_id=33925" className="btn btn-primary ">Book Now</a>
                           
                  </p>
                  </div>
            </div>
        </div>
       </div>
       <Footer />
    </div>
  )
}
