import axios from 'axios';


const API_URL = 'http://sayulitacasitas.com/api/';
//const API_URL = process.env.REACT_APP_API_ENDPOINT;


class ContactService {
  getAllContact() {
    return axios.get(API_URL + 'contacts');
  }

  get(id) {
    return axios.get(API_URL + `contacts/${id}`);
  }

  create(data) {
    return axios.post(API_URL + 'contacts', data);
  }

  update(id, data) {
    return axios.get(API_URL + `contacts/${id}`, data);
  }

  delete(id) {
    return axios.get(API_URL + `contacts/${id}`);
  }

  deleteAll() {
    return axios.get(API_URL + 'contacts');
  }

  findByTitle(title) {
    return axios.get(API_URL + `contacts?title=${title}`);
  }
}

  
export default new ContactService();
