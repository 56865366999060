import React, { useState, useEffect, Component } from 'react'
import PageTop from './title/PageTop'
import ContactinfoService from '../services/contactinfo.service';
import AuthService from "../services/auth.service";
import "react-quill/dist/quill.snow.css";
import { Navigate, Link } from "react-router-dom";


export default function ContactinfoList() {
  const [APIData, setAPIData] = useState([]);
  const [user, setUser] = useState("{redirect: null, userReady: false, currentUser: { username: '' }}");
  
    useEffect(() => {
      const currentUser = AuthService.getCurrentUser();
      if (!currentUser) {
      setUser({ redirect: "/" });
      }  else {
        setUser({ currentUser: currentUser, userReady: true })
      }
      ContactinfoService.getAllContactinfo().then(
        response => {
            setAPIData(response.data);
        },
        error => {
            console.log(error.data)
            //setAPIData(response.data);
        }
      );
    }, []);
    console.log("APIData value",APIData)
      if (user.redirect) {
        return <Navigate to={user.redirect} />
      }
  
     

  return (
    <>
    {(user.userReady) ?
    <div>
        <PageTop PageTitle="Contact Info"/>
        
        <div className="container my-5 contact_wrap1 bg_light" >
            <div className="row g-0">
                <div className="col-md-12">
                        <h4 className="mb-4 mt-4">Contact Info</h4>
                        <table className="table table-hover table-striped">
                        <thead className="thead-dark">
                <tr>
                    <th>Us Phone</th>
                    <th>Mex Phone</th>
                    <th>WhatsApp</th>
                    <th>Email</th>
                    <th>Action</th>
                    
                </tr>
            </thead>
            <tbody>
                { APIData.length > 0 ? (
                    APIData.map(contactinfo => {
                        const {id, usphone, mexphone, whatsapp, email, officehour} = contactinfo;
                        return (
                            <tr key={id}>
                                <td>{usphone}</td>
                                <td>{mexphone}</td>
                                <td>{whatsapp}</td>
                                <td>{email}</td>
                                <td><Link to={`/editcontactinfo/${id}`} state={contactinfo} className="btn btn-secondary">Edit </Link></td>
                                </tr>
                        )
                    })
                ) : (
                    <tr>
                        <td colSpan={7}>No ConactInfo found</td>
                    </tr>
                )   
                }
            </tbody>
                        </table>
                </div>
            </div>
        </div>
     </div>: null}
    </>
  )
}
