import React, { useState } from "react";
import PT from "prop-types";
import {
  LightgalleryProvider,
  LightgalleryItem
} from "react-lightgallery";

const PhotoItem = ({ image, thumb, group }) => (
  <div className="gl_item">
    {/* <LightgalleryItem group={group} src={image} thumb={thumb}>
      <img src={image} style={{ width: "100%" }} /> */}
      <LightgalleryItem group={group} src={`../upload/${image}`} thumb={thumb}>
      <img src={`../upload/${image}`} style={{ width: "100%" }} />
    </LightgalleryItem>
  </div>
);
PhotoItem.propTypes = {
  image: PT.string.isRequired,
  thumb: PT.string,
  group: PT.string.isRequired
};

export default function Gallery({galleryImages}) {
  return (
    <div className="content">

      <div>
        {galleryImages ? (
          <LightgalleryProvider
            onBeforeOpen={() => console.info("onBeforeOpen")}
            onAfterOpen={() => console.info("onAfterOpen")}
            onSlideItemLoad={() => console.info("onSlideItemLoad")}
            onBeforeSlide={() => console.info("onBeforeSlide")}
            onAfterSlide={() => console.info("onAfterSlide")}
            onBeforePrevSlide={() => console.info("onBeforePrevSlide")}
            onBeforeNextSlide={() => console.info("onBeforeNextSlide")}
            onDragstart={() => console.info("onDragstart")}
            onDragmove={() => console.info("onDragmove")}
            onDragend={() => console.info("onDragend")}
            onSlideClick={() => console.info("onSlideClick")}
            onBeforeClose={() => console.info("onBeforeClose")}
            onCloseAfter={() => console.info("onCloseAfter")}
          >
            
            <div className="gallery_list">
              {galleryImages.map((p, idx) => (
                <PhotoItem key={idx} image={p} group="group2" />
              ))}
            </div>
          </LightgalleryProvider>
        ) : null}
      </div>
    </div>
  );
}


