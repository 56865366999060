import axios from 'axios';


const API_URL = 'http://sayulitacasitas.com/api/';


class PoliciesService {
  getAllPolicies() {
    return axios.get(API_URL + 'policiess');
  }

  get(id) {
    return axios.get(API_URL + `policiess/${id}`);
  }

  create(data) {
    return axios.post(API_URL + 'policiess', data);
  }
  
  update(id, data) {
    return axios.put(API_URL + `policiess/${id}`, data);
  }

  delete(id) {
    return axios.get(API_URL + `policiess/${id}`);
  }

  deleteAll() {
    return axios.get(API_URL + 'policiess');
  }

  findByTitle(title) {
    return axios.get(API_URL + `policiess?title=${title}`);
  }
}

  
export default new PoliciesService();
