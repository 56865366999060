import React, { useState, useEffect, Component } from 'react'
import PageTop from './title/PageTop'

//import './Contact.css';
import TestimonialService from '../services/testmonial.service';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import Logo from '../assets/images/logo.svg';
//import './Contact.css';

import AuthService from "../services/auth.service";

import { withRouter } from '../common/with-router';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from 'axios';
import { Link, Navigate } from "react-router-dom";
var FormData = require('form-data');




export default function AddTestimonial() {
    const [room_id, setRoomId] = useState("");
    const [villa_id, setVillaId] = useState("");
    const [villa_name, setVillaName] = useState("");
    const [room_name, setRoomName] = useState("");
    const [name, setName] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [rating, setRating] = useState("");
    const [place, setPlace] = useState("");
    const [staydate, setStayDate] = useState("");
    const [user, setUser] = useState("{redirect: null, userReady: false, currentUser: { username: '' }}");
    const [status, setStatus] = useState(undefined);

    const resetTestimonial = () => {
        setRoomId("");
        setVillaId("");
        setName("");
        setTitle("");
        setDescription("");
        setRating("");
        setStayDate("");
        setPlace("");
        }

    useEffect(() => {
      const currentUser = AuthService.getCurrentUser();
      if (!currentUser) {
      setUser({ redirect: "/home" });
      }  else {
        setUser({ currentUser: currentUser, userReady: true })
      }
    }, []);
      
  
    const handleSubmit= async (e) => {
        e.preventDefault();
        
        var data = {
          room_id: room_id,
          villa_id: villa_id,
          villa_name: villa_name,
          room_name:room_name,
          name: name,
          title: title,
          description: description,
          rating: rating,
          staydate: staydate,
          place:place,
         };
          
              TestimonialService.create(data)
          .then(response => {
          console.log(response.data);
          resetTestimonial();
          setStatus({ type: 'success' });
          })
          .catch(e => {
            console.log(e);
            setStatus({ type: 'error', e });
          });
          setTimeout(function() {
            setStatus("");
            window.location.href = "/testimoniallist";
           }, 2000);
          }
          if (user.redirect) {
            return <Navigate to={user.redirect} />
          }
  
     

  return (
    <>
    {(user.userReady) ?
    <div>
        <PageTop PageTitle="Add Testimonial"/>
        <div className="container my-5 bg_light">
            <div className="row g-0">
                <div className="col-md-12">
                    <form onSubmit={e => {handleSubmit(e)}} className="p-3" >
                        <h4 className="mb-4">Add Testimonial</h4>
                        <div className="row g-2">
                        <div className="col-md-6">
                        <select required className="form-select mb-2" value={villa_id} name="villa_id" onChange={e =>{
                                  var selectedOption = e.target.selectedOptions[0];
                                  setVillaId(selectedOption.value)
                                  setVillaName(selectedOption.text)
                               }}>
                                    <option value="" disabled>Property Name</option>
                                    <option value="1006">Casa Pata Salada</option>
                                    <option value="1007">La Esquina</option>
                                </select> 
                         </div>
                         <div className="col-md-6">
                          
                         {villa_id ==="1006" ? (
         <select required className="form-select mb-2" value={room_id} name="room_id" onChange={e =>{
          var selectedOption = e.target.selectedOptions[0];
          setRoomId(selectedOption.value)
          setRoomName(selectedOption.text)
       }}>
            <option value="" disabled>Unit Name</option>
            <option value="116478">Casita #1 Ocean View</option>
            <option value="116479">Casita #2 Garden View</option>
            <option value="116480">Casita #3 Garden View</option>
            <option value="116481">Casita #4 Ocean View</option>
            <option value="116482">Casita #5 Ocean View</option>
            <option value="116483">Casita #6 Ocean View</option>
            <option value="116484">Casita #7 Ocean View</option>
            <option value="129786">Casita #8 Ocean View</option>
            <option value="129788">Casita #9 Ocean View</option>
        </select> 
      ) : (
        <select required className="form-select mb-2" value={room_id} name="room_id" onChange={e =>{
          var selectedOption = e.target.selectedOptions[0];
          setRoomId(selectedOption.value)
          setRoomName(selectedOption.text)
       }}>
            <option value="" disabled>Unit Name</option>
            <option value="116469">LARGE LE Room (LE2)</option>
            <option value="116470">LARGE LE Room (LE3)</option>
            <option value="116471">LARGE LE Room (LE6)</option>
            <option value="116472">LARGE LE Room (LE7)</option>
            <option value="116473">SMALL LE Room (LE1)</option>
            <option value="116474">SMALL LE Room (LE4)</option>
            <option value="116475">SMALL LE Room (LE5)</option>
            <option value="116476">SMALL LE Room (LE8)</option>
            <option value="116477">The Penthouse Room (LE)</option>
        </select> 
      )}
                             </div>
                             <div className="col-md-4">
                             <input required type="text" className="form-control mb-2" placeholder="Name" value={name} name="name" onChange={e => setName(e.target.value)}/>
                             </div>
                             <div className="col-md-4">
                             <input required type="text" className="form-control mb-2" placeholder="Visited From" value={place} name="place" onChange={e => setPlace(e.target.value)}/>
                             </div>
                             <div className="col-md-4">
                             <input required type="date" className="form-control down_arrow input_icon text-start"  placeholder="Book By" value={staydate} name="staydate" onChange={e => setStayDate(e.target.value)}/>
                             </div>
                             <div className="col-md-6">
                             <input required type="text" className="form-control mb-2" placeholder="Title" value={title} name="title" onChange={e => setTitle(e.target.value)}/>
                             </div>
                             <div className="col-md-6">
                              <select required className="form-select mb-2" value={rating} name="rating" onChange={e => setRating(e.target.value)}>
                                    <option value="" disabled>Rating Star</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select> 
                              </div>
                             <div className="col-md-12">
                             <textarea required className="form-control mb-2" cols="5" rows="4" placeholder="Description" value={description} name="description" onChange={e => setDescription(e.target.value)}></textarea>
                             </div>
                             <div className="col-md-12">
                                <div className='d-flex gap-3'>
                                <button type="submit" className="btn btn-primary ">Submit</button>
                                <Link to='/testimoniallist' className="btn btn-secondary ">Cancel</Link>
                                </div>
                            </div>
                            <div className="col-md-12 my-3">
                                <div className="form-check mb-3 mt-2">
                                {status?.type === 'success' && <p>Your information was successfully submitted!</p>}
                                {status?.type === 'error' && (
                                  <p>Error in Form Submission please try again!!!</p>
                                )}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                
            </div>
        </div>
        <div className="my-5 text-center pb-4 text-capitalize"><h2>Enjoy a superb Sayulita vacation!</h2></div>
    </div>: null}
    </>
  )
}
