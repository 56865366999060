import axios from 'axios';


const API_URL = 'http://sayulitacasitas.com/api/';


class ReservationService {
  getAllReservation() {
    return axios.get(API_URL + 'reservations');
  }

  get(id) {
    return axios.get(API_URL + `reservations/${id}`);
  }

  create(data) {
    return axios.post(API_URL + 'reservations', data);
  }

  update(id, data) {
    return axios.get(API_URL + `reservations/${id}`, data);
  }

  delete(id) {
    return axios.get(API_URL + `reservations/${id}`);
  }

  deleteAll() {
    return axios.get(API_URL + 'reservations');
  }

  findByTitle(title) {
    return axios.get(API_URL + `reservations?title=${title}`);
  }
}

  
export default new ReservationService();
