import React, { useEffect, useState }  from 'react'
import PageTop from '../../components/title/PageTop'
import Footer from '../../components/footer/Footer';
import PoliciesService from "../../services/policies.service";
import parse from 'html-react-parser'

export default function Policies() {
  const [APIData, setAPIData] = useState([]);
    useEffect(() => {
           PoliciesService.getAllPolicies().then(
                response => {
                    setAPIData(response.data);
                },
                error => {
                    console.log(error)
                }
              );
    }, []);
    console.log("APIData value",APIData)
  return (
    <div>
        <PageTop PageTitle="Policies"/>
       <div className="container py-5">
        <div className="row">
            <div className="col-lg-8 mx-auto col-md-12">
                <div className="textpage_content py-5">
                {APIData.map((data) => {
                                    return (
                                      <>{parse(data.description || '')}</>
                                      )
                               })}
                   </div>
            </div>
        </div>
       </div>
       <Footer />
    </div>
  )
}
