import React, { useState, useEffect, Component } from 'react'
import PageTop from './title/PageTop'
import PoliciesService from '../services/policies.service';
import AuthService from "../services/auth.service";
import { Navigate, Link } from "react-router-dom";
import parse from 'html-react-parser'



export default function PoliciesList() {
  const [APIData, setAPIData] = useState([]);
  const [user, setUser] = useState("{redirect: null, userReady: false, currentUser: { username: '' }}");
  
    useEffect(() => {
      const currentUser = AuthService.getCurrentUser();
      if (!currentUser) {
      setUser({ redirect: "/" });
      }  else {
        setUser({ currentUser: currentUser, userReady: true })
      }
      PoliciesService.getAllPolicies().then(
        response => {
            setAPIData(response.data);
        },
        error => {
            console.log(error.data)
            //setAPIData(response.data);
        }
      );
    }, []);
    
      if (user.redirect) {
        return <Navigate to={user.redirect} />
      }
  
     

  return (
    <>
    {(user.userReady) ?
    <div>
        <PageTop PageTitle="Policies Section"/>
        
        <div className="container my-5 contact_wrap1 bg_light" >
            <div className="row g-0">
                <div className="col-md-12">
                
                        <h4 className="mb-4 mt-4">Policies Section</h4>
                        {/* <div className="col-md-3 mb-4 mt-4"><Link to='/addpolicies' className="btn btn-primary w-100">Add Policies</Link></div> */}
                        <table className="table table-hover table-striped">
                        <thead className="thead-dark">
                <tr>
                    <th>ID</th>
                    <th>Description</th>
                    <th>Action</th>
                    </tr>
            </thead>
            <tbody>
                { APIData.length > 0 ? (
                    APIData.map(policies => {
                        const {id, description} = policies;
                        return (
                            <tr key={id}>
                                <td>{id}</td>
                                <td>Sayulita Casitas Policies</td>
                                <td><Link to={`/editpolicies/${id}`} state={policies} className="btn btn-secondary">Edit </Link></td>
                                </tr>
                        )
                    })
                ) : (
                    <tr>
                        <td colSpan={3}>No Policies found</td>
                    </tr>
                )   
                }
            </tbody>
                        </table>
                </div>
            </div>
        </div>
     </div>: null}
    </>
  )
}
