import React from 'react';
import './Title.css';

export default function PageTop(props) {
  var title = "";
  if(props.PageTitle){
    title = props.PageTitle;
  }
  
  return (
    <section className={title.slice(0,2) == "La" ? "page_top la" : "page_top casa"} >
        <div className="page_top_title">
          <h1>{props.PageTitle}</h1>
          <div className="page_name_subtitle">{props.PageSubTitle}</div>
        </div>
    </section>
  )
}
