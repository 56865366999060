import React from 'react'
import Img1 from '../../assets/images/img1.jpg';
import Sign from '../../assets/images/Brooke.png';
import './Welcome.css';
import Title from '../title/Title';

export default function Welcome() {
  return (
    <section className='welcome py-5 bg_light'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='text_block'>
                        <Title
                            main='Sayulita Beachfront Vacation Rentals'
                            subTitle='Welcome to Sayulita Casitas'
                        />
                        
                        <p>Sayulita Casitas consists of two of the most recognizable vacation spots in Sayulita: Casa Pata Salada and La Esquina. Whether you prefer rustic colorful casitas or studios with a more modern and sleek style, Sayulita Casitas is bound to have the perfect option for your vacation. Great service, excellent amenities, and breathtaking views await you. We look forward to having you as our guest!</p>
                        <div className="signature mt-4">
                            <img src={Sign} className='img-fluid' alt=''/>
                            <div>Brooke - Manager</div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className="img_block"><img src={Img1} className='img-fluid' alt=''/></div>
                </div>
            </div>
        </div>
    </section>
  )
}
