import React, { useEffect, useState }  from 'react'
import {  Link } from "react-router-dom"
import PageTop from '../../components/title/PageTop';
import Cps_1 from '../../assets/images/cps-ls1.jpg';
import Title from '../../components/title/Title';
import { useLocation } from "react-router-dom";
import VillaService from '../../services/villa.service';
import parse from 'html-react-parser'
import _ from "lodash";
import Footer from '../../components/footer/Footer';
import { MdOutlineKingBed, MdOutlineKitchen, MdOutlineBathtub, MdFormatQuote } from "react-icons/md";


export default function VillaList() {
    const location = useLocation();
console.log(location);
console.log("location.state details",location.state);
const [APIData, setAPIData] = useState([]);
const [CasaData, setCasaData] = useState([]);
const [LaData, setLaData] = useState([]);
const [CasaSearchData, setCasaSearchData] = useState([]);
const [LaSearchData, setLaSearchData] = useState([]);
const [GuestCount, setGuestCount] = useState();
var total = 0;

useEffect(() => {
    VillaService.getAllVilla().then(
         response => {
             const firstArray = response.data;
             let casaresults = response.data.filter(obj => {
                return obj.villa_id === 1006;
              });
              setCasaData(casaresults);
            //  console.log("CasaData value",CasaData) 
             let laresults = response.data.filter(obj => {
                return obj.villa_id === 1007;
              });
              setLaData(laresults);
            //  console.log("LaData value",LaData)
         
             if(location.state == null){
                setAPIData(response.data);
             }else if(location.state.page === "home"){
                setAPIData(response.data);
                }else if(location.state.page === "slider")
             {
                const secondArray = location.state.data;
                if(secondArray.length===0){
                    console.log("no Rooms Available")
                    return <section className="villa_units pb-5"><h1>No Rooms</h1></section>

                }else{
                    console.log("firstArray",firstArray )
                    console.log("secondArray",secondArray )
                    const thirdArray = firstArray.filter((elem) => {
                        return secondArray.some((ele) => {
                        return ele.room_id === elem.room_id;
                          });
                        });
                        setAPIData(thirdArray); 
                        //console.log("APIData value 55 line ", APIData)
                        let casaresults = thirdArray.filter(obj => {
                            return obj.villa_id === 1006 && location.state.total_guest <= obj.max_guest;
                          });
                          setCasaSearchData(casaresults);
                        //  console.log("CasaData value thirdArray",CasaSearchData) 
                         let laresults = thirdArray.filter(obj => {
                            return obj.villa_id === 1007  && location.state.total_guest <= obj.max_guest;
                          });
                          setLaSearchData(laresults);
                         // console.log("LaData value thirdArray",LaSearchData)
                }
             
            }
         },
         error => {
             console.log(error)
         }
       );
}, []);
/* const firstArray = APIData;
const secondArray = location.state;
const thirdArray = firstArray.filter((elem) => {
    return secondArray.some((ele) => {
    return ele.room_id === elem.room_id;
      });
    });
    setAPIData(thirdArray); */
  return (
    <div>
        <PageTop PageTitle="Available Villa Units"/>
        <section className="villa_units pb-5">
            <div className="container my-5">
            {(() => {if ((location.state && location.state.page === "home" && location.state.data === 1006) || (location.state === null)){
                                        return (
                                            <h2>Casa Pata Salada</h2>
                                        )
                                    }
                                    
                                    
                                    })()}
           
                                    {CasaData.map((data) => {

if((location.state === null) || (location.state.data === 1006 && data.villa_id === 1006)){
    return (
<div className="row bg_light g-0 mb-4" key={data.id}>
<div className='col-md-4'>
<div className="unit_img p-3"><img className='img-fluid' src={`../upload/${data.featured_image}`} alt=''/></div>
</div>
<div className='col-md-8'>
<div className="unit_info pt-3 px-3 pb-4">
<div className="card-body">
<h5 className="card-title">{data.room_name}<span className="max_guest">{data.max_guest} Guests Max</span></h5>
<ul className='pro_features mt-3'>
{(() => {if (data.king_bed > 0){
                        return (
                            <li><MdOutlineKingBed/> <span>{data.king_bed} King Bed</span></li>
                          )
                    }
                    })()}
        {(() => {if (data.queen_bed > 0){
                        return (
                            <li><MdOutlineKingBed/> <span>{data.queen_bed} Queen Bed</span></li>
                          )
                    }
                    })()}            
            {(() => {if (data.sofa_bed > 0){
                        return (
                            <li><MdOutlineKingBed/> <span>{data.sofa_bed} Sofa Bed</span></li>
                          )
                    }
                    })()}            
            {(() => {if (data.trundle_bed > 0){
                        return (
                            <li><MdOutlineKingBed/> <span>{data.trundle_bed} Trundle Bed</span></li>
                          )
                    }
                    })()}            
            <li><MdOutlineKitchen/> <span> Fully Equipped Kitchen</span></li>
            <li><MdOutlineBathtub/> <span>{data.bathroom} Bathroom</span></li>
</ul>
{parse(data.description || '')}
{parse(data.accommodations || '')}
</div>
<div className='btns mt-4'>
{/* <Link to='/reservation' state={{ villa: data.villa_name, unit: data.room_name, page: "unit" }} className="btn btn-primary">BOOK NOW!</Link> */}
<a href={`https://book.webrez.com/v30/#/property/2464/location/0/search?invtype_id=${data.invtype_id}&inventory_id=${data.room_id}`} target='_blank' className="btn btn-primary">BOOK NOW!</a>
<Link to={`/villas/${data.id}`} className="btn btn-light ms-2">View Details</Link>
<Link to={`https://secure.webrez.com/hotel/2464/calendar/?invtype_id=${data.invtype_id}`} target='_blank' className="btn btn-light ms-2">Check Availability</Link>
</div>
</div>
</div>
</div>

)
}

})}

{(() => {if ((location.state && location.state.page === "home" && location.state.data === 1007) || (location.state === null)){
                                        return (
                                            <h2>La Esquina</h2>
                                        )
                                    }
                                    
                                    
                                    })()}

{LaData.map((data) => {

if((location.state === null) || (location.state.data === 1007 && data.villa_id === 1007)){
    return (
<div className="row bg_light g-0 mb-4" key={data.id}>
<div className='col-md-4'>
<div className="unit_img p-3"><img className='img-fluid' src={`../upload/${data.featured_image}`} alt=''/></div>
</div>
<div className='col-md-8'>
<div className="unit_info pt-3 px-3 pb-4">
<div className="card-body">
<h5 className="card-title">{data.room_name}<span className="max_guest">{data.max_guest} Guests Max</span></h5>
<ul className='pro_features mt-3'>
{(() => {if (data.king_bed > 0){
                        return (
                            <li><MdOutlineKingBed/> <span>{data.king_bed} King Bed</span></li>
                          )
                    }
                    })()}
        {(() => {if (data.queen_bed > 0){
                        return (
                            <li><MdOutlineKingBed/> <span>{data.queen_bed} Queen Bed</span></li>
                          )
                    }
                    })()}            
            {(() => {if (data.sofa_bed > 0){
                        return (
                            <li><MdOutlineKingBed/> <span>{data.sofa_bed} Sofa Bed</span></li>
                          )
                    }
                    })()}            
            {(() => {if (data.trundle_bed > 0){
                        return (
                            <li><MdOutlineKingBed/> <span>{data.trundle_bed} Trundle Bed</span></li>
                          )
                    }
                    })()}            
            <li><MdOutlineKitchen/> <span> Fully Equipped Kitchen</span></li>
            <li><MdOutlineBathtub/> <span>{data.bathroom} Bathroom</span></li>
</ul>
{parse(data.description || '')}
</div>
<div className='btns mt-4'>
{/* <Link to='/reservation' state={{ villa: data.villa_name, unit: data.room_name, page: "unit" }} className="btn btn-primary">BOOK NOW!</Link> */}
<a href={`https://book.webrez.com/v30/#/property/2464/location/0/search?invtype_id=${data.invtype_id}&inventory_id=${data.room_id}`} target='_blank' className="btn btn-primary">BOOK NOW!</a>
<Link to={`/villas/${data.id}`} className="btn btn-light ms-2">View Details</Link>
<Link to={`https://secure.webrez.com/hotel/2464/calendar/?invtype_id=${data.invtype_id}`} target='_blank' className="btn btn-light ms-2">Check Availability</Link>
</div>
</div>
</div>
</div>

)
}

})}

{/* ============================== Sarch data Listing   =================================================== */}
{(() => {if (CasaSearchData.length > 0){
                                        return (
                                            <h2>Casa Pata Salada</h2>
                                        )
                                    }
                                    
                                    
                                    })()}
           
                                    {CasaSearchData.map((data) => {

if(CasaSearchData.length > 0){
    return (
<div className="row bg_light g-0 mb-4" key={data.id}>
<div className='col-md-4'>
<div className="unit_img p-3"><img className='img-fluid' src={`../upload/${data.featured_image}`} alt=''/></div>
</div>
<div className='col-md-8'>
<div className="unit_info pt-3 px-3 pb-4">
<div className="card-body">
<h5 className="card-title">{data.room_name}<span className="max_guest">{data.max_guest} Guests Max</span></h5>
<ul className='pro_features mt-3'>
{(() => {if (data.king_bed > 0){
                        return (
                            <li><MdOutlineKingBed/> <span>{data.king_bed} King Bed</span></li>
                          )
                    }
                    })()}
        {(() => {if (data.queen_bed > 0){
                        return (
                            <li><MdOutlineKingBed/> <span>{data.queen_bed} Queen Bed</span></li>
                          )
                    }
                    })()}            
            {(() => {if (data.sofa_bed > 0){
                        return (
                            <li><MdOutlineKingBed/> <span>{data.sofa_bed} Sofa Bed</span></li>
                          )
                    }
                    })()}            
            {(() => {if (data.trundle_bed > 0){
                        return (
                            <li><MdOutlineKingBed/> <span>{data.trundle_bed} Trundle Bed</span></li>
                          )
                    }
                    })()}            
            <li><MdOutlineKitchen/> <span> Fully Equipped Kitchen</span></li>
            <li><MdOutlineBathtub/> <span>{data.bathroom} Bathroom</span></li>
</ul>
{parse(data.description || '')}
{parse(data.accommodations || '')}
</div>
<div className='btns mt-4'>
{/* <Link to='/reservation' state={{ villa: data.villa_name, unit: data.room_name, page: "unit" }} className="btn btn-primary">BOOK NOW!</Link> */}
<a href={`https://secure.webrez.com/Bookings105/activity-edit.html?hotel_id=2464&table=transactions&listing_id=-1&invtype_id=${data.invtype_id}&package_id=${data.package_id}&date_from=${location.state.fromdate}&date_to=${location.state.todate}&num_children=${location.state.child}&num_adults=${location.state.adult}&num_babies=0&mode=edit&sourcecode_id=&reservationcode_id=&access_code=&guest_password=&guest_email=&version=2&`} target='_blank' className="btn btn-primary">BOOK NOW!</a>
<Link to={`/villas/${data.id}`} className="btn btn-light ms-2">View Details</Link>
<Link to={`https://secure.webrez.com/hotel/2464/calendar/?invtype_id=${data.invtype_id}`} target='_blank' className="btn btn-light ms-2">Check Availability</Link>
</div>
</div>
</div>
</div>

)
}

})}

{(() => {if (LaSearchData.length > 0){
                                        return (
                                            <h2>La Esquina</h2>
                                        )
                                    }
                                    
                                    
                                    })()}

{LaSearchData.map((data) => {

if(LaSearchData.length > 0){
    return (
<div className="row bg_light g-0 mb-4" key={data.id}>
<div className='col-md-4'>
<div className="unit_img p-3"><img className='img-fluid' src={`../upload/${data.featured_image}`} alt=''/></div>
</div>
<div className='col-md-8'>
<div className="unit_info pt-3 px-3 pb-4">
<div className="card-body">
<h5 className="card-title">{data.room_name}<span className="max_guest">{data.max_guest} Guests Max</span></h5>
<ul className='pro_features mt-3'>
{(() => {if (data.king_bed > 0){
                        return (
                            <li><MdOutlineKingBed/> <span>{data.king_bed} King Bed</span></li>
                          )
                    }
                    })()}
        {(() => {if (data.queen_bed > 0){
                        return (
                            <li><MdOutlineKingBed/> <span>{data.queen_bed} Queen Bed</span></li>
                          )
                    }
                    })()}            
            {(() => {if (data.sofa_bed > 0){
                        return (
                            <li><MdOutlineKingBed/> <span>{data.sofa_bed} Sofa Bed</span></li>
                          )
                    }
                    })()}            
            {(() => {if (data.trundle_bed > 0){
                        return (
                            <li><MdOutlineKingBed/> <span>{data.trundle_bed} Trundle Bed</span></li>
                          )
                    }
                    })()}            
            <li><MdOutlineKitchen/> <span> Fully Equipped Kitchen</span></li>
            <li><MdOutlineBathtub/> <span>{data.bathroom} Bathroom</span></li>
</ul>
{parse(data.description || '')}
</div>
<div className='btns mt-4'>
{/* <Link to='/reservation' state={{ villa: data.villa_name, unit: data.room_name, page: "unit" }} className="btn btn-primary">BOOK NOW!</Link> */}
<a href={`https://secure.webrez.com/Bookings105/activity-edit.html?hotel_id=2464&table=transactions&listing_id=-1&invtype_id=${data.invtype_id}&package_id=${data.package_id}&date_from=${location.state.fromdate}&date_to=${location.state.todate}&num_children=${location.state.child}&num_adults=${location.state.adult}&num_babies=0&mode=edit&sourcecode_id=&reservationcode_id=&access_code=&guest_password=&guest_email=&version=2&`} target='_blank' className="btn btn-primary">BOOK NOW!</a>
<Link to={`/villas/${data.id}`} className="btn btn-light ms-2">View Details</Link>
<Link to={`https://secure.webrez.com/hotel/2464/calendar/?invtype_id=${data.invtype_id}`} target='_blank' className="btn btn-light ms-2">Check Availability</Link>
</div>
</div>
</div>
</div>

)
}

})}
{(() => {if (location.state && location.state.page === "slider" && LaSearchData.length == 0 && CasaSearchData.length == 0){
                                        return (
                                            <div className='text-center'>
                                            <h2>No Rooms are Available for the Given Guest Count,</h2>
                                            <h3>please make an Inquire.</h3>
                                            <Link to='/reservation' className="btn btn-primary mt-1 px-3">Inquire</Link>
                                            </div>
                                            
                                        )
                                    }
                                    
                                    
                                    })()}

            {/* {APIData.map((data) => {
                if(location.state && location.state.page === "slider"){
                                    return (
                <div className="row bg_light g-0 mb-4" key={data.id}>
                    <div className='col-md-4'>
                        <div className="unit_img p-3"><img className='img-fluid' src={`../upload/${data.featured_image}`} alt=''/></div>
                    </div>
                    <div className='col-md-8'>
                        <div className="unit_info pt-3 px-3 pb-4">
                            <div className="card-body">
                                <h5 className="card-title">{data.room_name}</h5>
                                <ul className='pro_features mt-3'>
                                {(() => {if (data.king_bed > 0){
                                        return (
                                            <li><MdOutlineKingBed/> <span>{data.king_bed} King Bed</span></li>
                                          )
                                    }
                                    })()}
                        {(() => {if (data.queen_bed > 0){
                                        return (
                                            <li><MdOutlineKingBed/> <span>{data.queen_bed} Queen Bed</span></li>
                                          )
                                    }
                                    })()}            
                            {(() => {if (data.sofa_bed > 0){
                                        return (
                                            <li><MdOutlineKingBed/> <span>{data.sofa_bed} Sofa Bed</span></li>
                                          )
                                    }
                                    })()}            
                            {(() => {if (data.trundle_bed > 0){
                                        return (
                                            <li><MdOutlineKingBed/> <span>{data.trundle_bed} Trundle Bed</span></li>
                                          )
                                    }
                                    })()}            
                            <li><MdOutlineKitchen/> <span> Fully Equipped Kitchen</span></li>
                            <li><MdOutlineBathtub/> <span>{data.bathroom} Bathroom</span></li>
                                </ul>
                                {parse(data.description || '')}
                            </div>
                            <div className='btns mt-4'>
                                <Link to='/reservation' state={{ villa: data.villa_name, unit: data.room_name, page: "unit" }} className="btn btn-primary">BOOK NOW!</Link>
                                <Link to={`/villas/${data.id}`} className="btn btn-light ms-2">View Details</Link>
                            </div>
                        </div>
                    </div>
                </div>

                    )
                   }else if(location.state && location.state.page === "home" && data.villa_id === location.state.data){
                    return (
<div className="row bg_light g-0 mb-4" key={data.id}>
    <div className='col-md-4'>
        <div className="unit_img p-3"><img className='img-fluid' src={`../upload/${data.featured_image}`} alt=''/></div>
    </div>
    <div className='col-md-8'>
        <div className="unit_info pt-3 px-3 pb-4">
            <div className="card-body">
                <h5 className="card-title">{data.room_name}</h5>
                <ul className='pro_features mt-3'>
                {(() => {if (data.king_bed > 0){
                                        return (
                                            <li><MdOutlineKingBed/> <span>{data.king_bed} King Bed</span></li>
                                          )
                                    }
                                    })()}
                        {(() => {if (data.queen_bed > 0){
                                        return (
                                            <li><MdOutlineKingBed/> <span>{data.queen_bed} Queen Bed</span></li>
                                          )
                                    }
                                    })()}            
                            {(() => {if (data.sofa_bed > 0){
                                        return (
                                            <li><MdOutlineKingBed/> <span>{data.sofa_bed} Sofa Bed</span></li>
                                          )
                                    }
                                    })()}            
                            {(() => {if (data.trundle_bed > 0){
                                        return (
                                            <li><MdOutlineKingBed/> <span>{data.trundle_bed} Trundle Bed</span></li>
                                          )
                                    }
                                    })()}            
                            <li><MdOutlineKitchen/> <span> Fully Equipped Kitchen</span></li>
                            <li><MdOutlineBathtub/> <span>{data.bathroom} Bathroom</span></li>
                </ul>
                {parse(data.description || '')}
            </div>
            <div className='btns mt-4'>
                <Link to='/reservation' state={{ villa: data.villa_name, unit: data.room_name, page: "unit" }} className="btn btn-primary">BOOK NOW!</Link>
                <Link to={`/villas/${data.id}`} className="btn btn-light ms-2">View Details</Link>
            </div>
        </div>
    </div>
</div>

    )
   }else if(location.state === null){
    return (
        <div className="row bg_light g-0 mb-4" key={data.id}>
            <div className='col-md-4'>
                <div className="unit_img p-3"><img className='img-fluid' src={`../upload/${data.featured_image}`} alt=''/></div>
            </div>
            <div className='col-md-8'>
                <div className="unit_info pt-3 px-3 pb-4">
                    <div className="card-body">
                        <h5 className="card-title">{data.room_name}</h5>
                        <ul className='pro_features mt-3'>
                        {(() => {if (data.king_bed > 0){
                                        return (
                                            <li><MdOutlineKingBed/> <span>{data.king_bed} King Bed</span></li>
                                          )
                                    }
                                    })()}
                        {(() => {if (data.queen_bed > 0){
                                        return (
                                            <li><MdOutlineKingBed/> <span>{data.queen_bed} Queen Bed</span></li>
                                          )
                                    }
                                    })()}            
                            {(() => {if (data.sofa_bed > 0){
                                        return (
                                            <li><MdOutlineKingBed/> <span>{data.sofa_bed} Sofa Bed</span></li>
                                          )
                                    }
                                    })()}            
                            {(() => {if (data.trundle_bed > 0){
                                        return (
                                            <li><MdOutlineKingBed/> <span>{data.trundle_bed} Trundle Bed</span></li>
                                          )
                                    }
                                    })()}            
                            <li><MdOutlineKitchen/> <span> Fully Equipped Kitchen</span></li>
                            <li><MdOutlineBathtub/> <span>{data.bathroom} Bathroom</span></li>
                        </ul>
                        {parse(data.description || '')}
                    </div>
                    <div className='btns mt-4'>
                        <Link to='/reservation' state={{ villa: data.villa_name, unit: data.room_name, page: "unit" }} className="btn btn-primary">BOOK NOW!</Link>
                        <Link to={`/villas/${data.id}`} className="btn btn-light ms-2">View Details</Link>
                    </div>
                </div>
            </div>
        </div>
        
            )

   }
   if(location.state && location.state.data.length===0 && location.state.page === "slider"){
    return (
        <div className="row bg_light g-0 mb-4"><h1>No Rooms Available</h1></div>
    )
}
                        })} */}
            </div>
        </section>
        <Footer />
    </div>
    
  )
}
