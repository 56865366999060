import React, { useState, useEffect, Component } from 'react'
import './Hero.css';
import SlideOne from './../../assets/images/slide1.png';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import parse from 'html-react-parser'
import SliderService from  '../../services/slider.service';
import fetchJsonp from "fetch-jsonp";
import LoadingSpinner from '../spinner/LoadingSpinner';
import ContactinfoService from '../../services/contactinfo.service';

export default function Hero() {
    const navigate = useNavigate();
    const [SliderData, setSliderData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [status, setStatus] = useState(undefined);
    const [fromdate, setFromDate] = useState("");
    const [todate, setToDate] = useState("");
    const [Adult, setAdult] = useState(0);
    const [Child, setChild] = useState(0);
   // const [apiData, setApiData] = useState("");
   const [isSliderLoading, setIsSliderLoading] = useState(false);

   const [APIData, setAPIData] = useState([]);
  useEffect(() => {
         ContactinfoService.getAllContactinfo().then(
              response => {
                  setAPIData(response.data);
              },
              error => {
                  console.log(error)
              }
            );
  }, []);
    
    useEffect(() => {
      setIsSliderLoading(true);
        SliderService.getAllSlider().then(
          response => {
              setSliderData(response.data);
              setLoading(false);
              setIsSliderLoading(false);
          },
          error => {
              console.log(error.data)
              //setSliderData(response.data);
              setIsSliderLoading(false);
          }
        );
      }, []);

      if (isLoading) {
       // <LoadingSpinner /> 
        return <div className="home_slider">.</div>;
        
      }
    
      const handleSubmit= async (e) => {
        setIsSliderLoading(true);
        e.preventDefault();
        let url =  "https://secure.webrez.com//Bookings105/activity-edit.html?table=hotels&listing_id=2464&mode=command&command=website_availabilitycalendar&hotel_id=2464&output=jsonp&date_from="+fromdate.replace(/-/g, '')+"&date_to="+todate.replace(/-/g, '')
            fetchJsonp(url)
  .then(function(response) {
    return response.json()
  }).then(function(json) {
   // setApiData(json)
   // console.log('parsed json', json)
    let apiData  = json;
   // console.log("apiData ======================",apiData)
    let available = [];
for (var key in apiData){
 // console.log( key);
 var unit = apiData[key];
 
  for (var ty in unit){
     // console.log( ty);
     var date = unit[ty];
      var arr = [];
      
      for (var av in date){
     arr.push(date[av].num_rooms)
     }
     // console.log(arr);
     if(arr.includes(0)){
       //  console.log("no rooms available " +ty)
     }else{
         //console.log("Book now rooms available " +ty)
         //console.log( ty);
         available.push({"room_id" : ty.slice(8)*1});
     }
     }
     }
     // console.log("room availabe === " +available)
      if(available.length>0){
        const total_guest = Number(Adult) + Number(Child);
        navigate('/units', {state: {data: available, page:"slider", adult: Adult, child: Child, total_guest: total_guest, fromdate: fromdate.replace(/-/g, ''), todate: todate.replace(/-/g, '')}})
      }else{
        setStatus({ type: 'error' });
        setTimeout(function() {
            setStatus("");
           }, 2000);
      }
      
  }).catch(function(ex) {
    console.log('parsing failed', ex)
  })
         
}
/* {isLSliderLoading ? <LoadingSpinner /> : isLSliderLoading = false} */
if (isSliderLoading) {
  // <LoadingSpinner /> 
   return <LoadingSpinner />;
   
 }
      
  return (
    
    <div className='home_slider'>
     
        <div className='slide_one'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='slide_text'>
                          
                        <>{parse(SliderData != undefined && SliderData[0].title || '')}</>  
                            <Link to='https://secure.webrez.com/hotel/2464/calendar' target = "_blank" className="btn btn-light">Check Availability</Link>
                        </div>
                        
                        <div className="home_search">
                            <form className="home_search_form" onSubmit={e => {handleSubmit(e)}}>
                            <div className="hs_select">
                                <input type="text" onFocus={(e) => (e.target.type = "date")} min={new Date().toISOString().split('T')[0]} required className="form-control"  placeholder="Arrive"  value={fromdate} name="fromdate" onChange={e => setFromDate(e.target.value)}/>
                            </div>
                            <div className="hs_select">
                                <input type="text" onFocus={(e) => (e.target.type = "date")}  disabled={fromdate === "" ? true: false} min={fromdate ? new Date(fromdate).toISOString().split("T")[0]: ""} required className="form-control"  placeholder="Depart"  value={todate} name="todate" onChange={e => setToDate(e.target.value)}/>
                            </div>
                            <div className="hs_select">
                                <select required className="form-select" value={Adult} name="Adult" onChange={e => setAdult(e.target.value)}>
                                    <option value="">Adults</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                </select>
                            </div>
                            <div className="hs_select">
                                <select className="form-select" value={Child} name="Child" onChange={e => setChild(e.target.value)}>
                                    <option value="">Children</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                </select>
                            </div>
                            {/* <div className="hs_select">
                                <select name="VillaType" className="form-select">
                                    <option value="" disabled>Villa Type</option>
                                    <option value="1">Casa Pata Salada</option>
                                    <option value="2">La Esquina</option>
                                </select>
                            </div> */}
                            <div className="hs_select">
                                <button type="submit" className="btn btn-primary">Find a Unit</button>
                            </div>
                            </form>
                            {status?.type === 'success' && <p>Your information was successfully submitted!</p>}
      {status?.type === 'error' && (
        <p className='text-white my-4 text-center'>No Rooms Available For Selected Dates!!!</p>
      )}
                            <p className='text-white my-4 text-center'><span>Need Help?  Call ~</span> 
                            {APIData.map((data) => {
                                    return (
                                      <>
                                      <a className=' text-white' href={`tel:+${data.usphone.replace(/[^0-9]/g, '')}`}>US: {data.usphone}</a>
              <a className=' text-white' href={`tel:+${data.mexphone.replace(/[^0-9]/g, '')}`}>MEX: {data.mexphone}</a>
              <a className=' text-white' target="_blank" href={`https://api.whatsapp.com/send?phone=${data.whatsapp.replace(/[^0-9]/g, '')}`}>WHATSAPP: {data.whatsapp}</a>
                              {/* <a href='tel:+13107346990' className=' text-white'>US: 310 734 6990</a>
                              <a href='tel:+13292913675' className=' text-white'>MX: 329 291 3675</a>
                              <a href='https://wa.me/+523222305327' target='_blank' className=' text-white'>WHATSAPP: +52 322 230 5327</a> */}
                              </>
                            )
                        })}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <img src={`../upload/${SliderData != undefined && SliderData[0].offer_image}`} className='img-fluid' /> 

    </div>
  )
}
