import React, { useState, useEffect  }  from 'react'
import { FiMail, FiMapPin, FiPhoneCall } from "react-icons/fi";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import './Footer.css';
import Logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import SubscriberService from '../../services/subscriber.service';
import parse from 'html-react-parser'
import ContactinfoService from '../../services/contactinfo.service';

export default function Footer() {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState(undefined);

  const [APIData, setAPIData] = useState([]);
  useEffect(() => {
         ContactinfoService.getAllContactinfo().then(
              response => {
                  setAPIData(response.data);
              },
              error => {
                  console.log(error)
              }
            );
  }, []);

  var data = {
    email: email,
  };
  const resetSubscriber = () => {
    setEmail("")
  }

  const handleSubscriberSubmit= (e) => {
    e.preventDefault();
    SubscriberService.create(data)
  .then(response => {
    resetSubscriber();
    setStatus({ type: 'success' });
  })
  .catch(error => {
    console.log(error);
    setStatus({ type: 'error', error });
  });
  setTimeout(function() {
    setStatus("");
   }, 2000);
  }
  return (
    <>
    <section className='newsletter_section sec_vline mt-3'>
        <div className='newsletter_wrap'>
            <div className='newsletter'><h2>Keep In Touch</h2><p>For the latest specials, news and updates subscribe below!</p></div>
            <form onSubmit={e => {handleSubscriberSubmit(e)}}>
            <div className="input-group my-3">
                <FiMail/>
                <input type="email" required maxLength= "50" className="form-control" placeholder="Email Address" aria-label="Email Address" aria-describedby="subscribe-button" value={email} name="email" onChange={e => setEmail(e.target.value)}/>
                <button className="btn btn-primary" type="submit" id="subscribe-button">Subscribe</button>
            </div>
            {status?.type === 'success' && <p>Your information was successfully submitted!</p>}
      {status?.type === 'error' && (
        <p>Error in Form Submission please try again!!!</p>
      )}
            </form>
        </div>
    </section>
    <footer className='pt-5 bg_dark pb-3'>
      <div className="container">
        <div className="row">
          <div className="col-md-4 text-center">
            <div className="address_box text-white-50">
              <FiMapPin/>
              <p>Casa Pata Salada S/N, Pescadores, 63734 Sayulita, Nay., Mexico</p>
            </div>
          </div>
          <div className="col-md-4 text-center">
            <a className="foot-brand" href="#"><img src={Logo}/></a>
          </div>
          <div className="col-md-4 text-center">
            <div className="address_box">
              <FiPhoneCall/>
              <p className=' text-white-50'>Reservations</p>
              <div className='d-flex flex-column'>
              {APIData.map((data) => {
                                    return (
                                      <>
              <a className=' text-white' href={`tel:+${data.usphone.replace(/[^0-9]/g, '')}`}>US: {data.usphone}</a>
              <a className=' text-white' href={`tel:+${data.mexphone.replace(/[^0-9]/g, '')}`}>MEX: {data.mexphone}</a>
              <a className=' text-white' target="_blank" href={`https://api.whatsapp.com/send?phone=${data.whatsapp.replace(/[^0-9]/g, '')}`}>WHATSAPP: {data.whatsapp}</a>
              </>
                            )
                        })} 
                       {/*  <a href='tel:+13107346990' className=' text-white'>US: 310 734 6990</a>
              <a href='tel:+13292913675' className=' text-white'>MX: 329 291 3675</a>
              <a href='https://wa.me/+523222305327' target='_blank' className=' text-white'>WHATSAPP: +52 322 230 5327</a>
               */}
              </div>
            </div>
          </div>
        </div>
        <div className='row my-3'>
          <div className='col-12'>
          <ul className="navbar-nav mx-auto my-4 mb-lg-0 text-white-50">
                    <li className="nav-item">
                      <Link to="/"  className="nav-link">Home</Link>
                    </li>
                    {/* <li className="nav-item">
                    <Link to="/offers"  className="nav-link">Offers</Link>
                    </li> */}
                    <li className="nav-item">
                    <Link to="/units" state={{ data: 1006, page: "home" }} className="nav-link">Casa Pata Salada</Link>
                    </li>
                    <li className="nav-item">
                    <Link to="/units" state={{ data: 1007, page: "home" }}  className="nav-link">La Esquina</Link>
                    </li>
                    <li className="nav-item">
                    <Link to="/testimonials"  className="nav-link">Testimonials</Link>
                    </li>
                    <li className="nav-item">
                    <Link to="/contact"  className="nav-link">Contact</Link>
                    </li>
                    <li className="nav-item">
                    <Link to="/faq"  className="nav-link">FAQ</Link>
                    </li>
                    <li className="nav-item">
                    <Link to="/policies"  className="nav-link">Policies</Link>
                    </li>
                </ul>
          </div>
        </div>
        <div className="row py-4">
          <div className="col-sm-6">
              <ul className="social_links p-0">
                <li><a href='https://www.facebook.com/sayulitacasitas' className='text-white-50'><FaFacebookF/></a></li>
                <li><a href='https://instagram.com/sayulitacasitas?igshid=YmMyMTA2M2Y=' className='text-white-50'><FaInstagram/></a></li>
                <li><a href='https://www.facebook.com/Laesquinasayulita' className='text-white-50'><FaFacebookF/></a></li>
              </ul>
          </div>
          <div className="col-sm-6 copy-text ">
              <small className='text-white-50'>© 2023 CPS & LE. All Rights Reserved.</small>
          </div>
        </div>
      </div>
    </footer>
    </>
  )
}
