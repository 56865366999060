import React, { useEffect, useState }  from 'react'
import {FaWifi, FaPaw,FaStar} from "react-icons/fa";
import {FiUser} from "react-icons/fi";
import PageTop from '../../components/title/PageTop';
import axios from 'axios';
import TestimonialService from "../../services/testmonial.service";
import parse from 'html-react-parser'
import _ from "lodash";
import moment from "moment";
import Footer from '../../components/footer/Footer';

export default function Testimonials() {
    const [APIData, setAPIData] = useState([]);
    useEffect(() => {
           TestimonialService.getAllTestimonial().then(
                response => {
                    setAPIData(response.data);
                },
                error => {
                    console.log(error)
                }
              );
    }, []);

    
  return (
    <div>
        <PageTop PageTitle="Testimonials"/>
        <div className='container testi_container'>
            <div className='row py-4'>
                <div className='col-12'>
                    <ul className="nav my-3 cpsle_tabs" id="pills-tab" role="tablist">
                        <li role="presentation">
                            <button className="btn active" id="cps-reviews-tab" data-bs-toggle="pill" data-bs-target="#cps-reviews" type="button" role="tab" aria-controls="cps-reviews" aria-selected="true">Casa Pata Salada</button>
                        </li>
                        <li role="presentation">
                            <button className="btn" id="le-reviews-tab" data-bs-toggle="pill" data-bs-target="#le-reviews" type="button" role="tab" aria-controls="le-reviews" aria-selected="false">La Esquina</button>
                        </li>
                    </ul>
                </div>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="cps-reviews" role="tabpanel" aria-labelledby="cps-reviews-tab" tabIndex="0">
                        <div className="mb-5 villa_reviews">
                            <div className="v_reviews mt-4">
                               
                                
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <h3>Reviews <span><FaStar/> 5.0</span></h3>
                                    </div>
                                    <div className="col-sm-6 col-lg-6">
                                        <ul className="rating_grade ps-0">
                                            <li className="d-flex align-items-center justify-content-start">
                                                <span className="me-3">Cleanliness</span>
                                                <div className="review_rating">
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                </div>
                                                <span className="ms-2">5/5</span>
                                            </li>
                                            <li className="d-flex align-items-center justify-content-start">
                                                <span className="me-3">Facilities</span>
                                                <div className="review_rating">
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                </div>
                                                <span className="ms-2">5/5</span>
                                            </li>
                                            <li className="d-flex align-items-center justify-content-start">
                                                <span className="me-3">Comfort</span>
                                                <div className="review_rating">
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                </div>
                                                <span className="ms-2">5/5</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-6 col-lg-6">
                                        <ul className="rating_grade ps-0">
                                            <li className="d-flex align-items-center justify-content-start">
                                                <span className="me-3">Location</span>
                                                <div className="review_rating">
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                </div>
                                                <span className="ms-2">5/5</span>
                                            </li>
                                            <li className="d-flex align-items-center justify-content-start">
                                                <span className="me-3">Value for Money</span>
                                                <div className="review_rating">
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                </div>
                                                <span className="ms-2">5/5</span>
                                            </li>
                                            <li className="d-flex align-items-center justify-content-start">
                                                <span className="me-3">Staff</span>
                                                <div className="review_rating">
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                    <small><FaStar/></small>
                                                </div>
                                                <span className="ms-2">5/5</span>
                                            </li>
                                        </ul>
                                    </div>
                                    
                                </div>
                                <div className="row review_list g-0">
                                {APIData.map((data) => {
                                    if(data.villa_id === 1006){
                                    return (
                                    <div className="col-lg-12 border-bottom" key={data.id}>
                                        <div className="revbox pb-4">
                                            <div className="rev_head mb-3">
                                                <div className="rev_by">
                                                    <div className="rev_icon"><FiUser/></div>
                                                    <div className="rev_name">
                                                        <div><b>{data.name}</b></div>
                                                        <span>{moment(data.staydate).utc().format('MMMM DD, YYYY')}</span>
                                                    </div>
                                                </div>
                                                <div className="rev_rate">
                                                    <div className="review_rating">
                                                     {_.times(data.rating, (i) => (<small key={i}><FaStar/></small>))} 
                                                    </div>
                                                    <span className="ms-2">{data.rating}/5</span>
                                                </div>
                                            </div>
                                            <div className="rev_body">
                                                <div className="rev_text">
                                                    <strong>{data.title}</strong>
                                                    <div>{parse(data.description)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )
                               }
                                    })}
                        {/* <div className="mt-4 mb-3"><a href="#" className="btn btn-light">Show All</a></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="le-reviews" role="tabpanel" aria-labelledby="le-reviews-tab" tabIndex="0">
                    <div className="mb-5 villa_reviews">
                            <div className="v_reviews mt-4">
                               
                                
                               <div className="row">
                                   <div className="col-md-12 mb-3">
                                       <h3>Reviews <span><FaStar/> 5.0</span></h3>
                                   </div>
                                   <div className="col-sm-6 col-lg-6">
                                       <ul className="rating_grade ps-0">
                                           <li className="d-flex align-items-center justify-content-start">
                                               <span className="me-3">Cleanliness</span>
                                               <div className="review_rating">
                                                   <small><FaStar/></small>
                                                   <small><FaStar/></small>
                                                   <small><FaStar/></small>
                                                   <small><FaStar/></small>
                                                   <small><FaStar/></small>
                                               </div>
                                               <span className="ms-2">5/5</span>
                                           </li>
                                           <li className="d-flex align-items-center justify-content-start">
                                               <span className="me-3">Facilities</span>
                                               <div className="review_rating">
                                                   <small><FaStar/></small>
                                                   <small><FaStar/></small>
                                                   <small><FaStar/></small>
                                                   <small><FaStar/></small>
                                                   <small><FaStar/></small>
                                               </div>
                                               <span className="ms-2">5/5</span>
                                           </li>
                                           <li className="d-flex align-items-center justify-content-start">
                                               <span className="me-3">Comfort</span>
                                               <div className="review_rating">
                                                   <small><FaStar/></small>
                                                   <small><FaStar/></small>
                                                   <small><FaStar/></small>
                                                   <small><FaStar/></small>
                                                   <small><FaStar/></small>
                                               </div>
                                               <span className="ms-2">5/5</span>
                                           </li>
                                       </ul>
                                   </div>
                                   <div className="col-sm-6 col-lg-6">
                                       <ul className="rating_grade ps-0">
                                           <li className="d-flex align-items-center justify-content-start">
                                               <span className="me-3">Location</span>
                                               <div className="review_rating">
                                                   <small><FaStar/></small>
                                                   <small><FaStar/></small>
                                                   <small><FaStar/></small>
                                                   <small><FaStar/></small>
                                                   <small><FaStar/></small>
                                               </div>
                                               <span className="ms-2">5/5</span>
                                           </li>
                                           <li className="d-flex align-items-center justify-content-start">
                                               <span className="me-3">Value for Money</span>
                                               <div className="review_rating">
                                                   <small><FaStar/></small>
                                                   <small><FaStar/></small>
                                                   <small><FaStar/></small>
                                                   <small><FaStar/></small>
                                                   <small><FaStar/></small>
                                               </div>
                                               <span className="ms-2">5/5</span>
                                           </li>
                                           <li className="d-flex align-items-center justify-content-start">
                                               <span className="me-3">Staff</span>
                                               <div className="review_rating">
                                                   <small><FaStar/></small>
                                                   <small><FaStar/></small>
                                                   <small><FaStar/></small>
                                                   <small><FaStar/></small>
                                                   <small><FaStar/></small>
                                               </div>
                                               <span className="ms-2">5/5</span>
                                           </li>
                                       </ul>
                                   </div>
                                   
                               </div>
                               <div className="row review_list g-0">
                               {APIData.map((data) => {
                                    if(data.villa_id === 1007){
                                    return (
                                    <div className="col-lg-12 border-bottom" key={data.id}>
                                        <div className="revbox pb-4">
                                            <div className="rev_head mb-3">
                                                <div className="rev_by">
                                                    <div className="rev_icon"><FiUser/></div>
                                                    <div className="rev_name">
                                                        <div><b>{data.name}</b></div>
                                                        <span>{moment(data.staydate).utc().format('MMMM DD, YYYY')}</span>
                                                    </div>
                                                </div>
                                                <div className="rev_rate">
                                                    <div className="review_rating">
                                                     {_.times(data.rating, (i) => (<small key={i}><FaStar/></small>))} 
                                                    </div>
                                                    <span className="ms-2">{data.rating}/5</span>
                                                </div>
                                            </div>
                                            <div className="rev_body">
                                                <div className="rev_text">
                                                    <strong>{data.title}</strong>
                                                    <div>{parse(data.description)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )
                               }
                                    })}
                                  </div>
                           </div>
                        </div>
                    </div>
                </div>
                <div className='col-12'>
                    
                </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}
