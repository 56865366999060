import React, { Component } from "react";
import { Routes, Route, Link } from "react-router-dom";
/* import "bootstrap/dist/css/bootstrap.min.css"; */

import './assets/css/main.css';
import AuthService from "./services/auth.service";

import Login from "./components/login.component";
import Register from "./components/register.component";
import Profile from "./components/profile.component";
import BoardUser from "./components/board-user.component";
import BoardModerator from "./components/board-moderator.component";
import BoardAdmin from "./components/board-admin.component";
import Home from "./pages/Home";
import Offers from "./pages/Offers";
import VillaList from "./pages/villas/VillaList";
import VillaRoom from "./pages/villas/VillaRoom";
import Testimonials from "./pages/reviews/Testimonials";
import Contact from "./pages/contact/Contact";
import Reservation from "./pages/reservation/Reservation";
import Footer from './components/footer/Footer'
import Header from './components/header/Header'

// import AuthVerify from "./common/auth-verify";
import EventBus from "./common/EventBus";
import AddVillaComponent from "./components/addVilla.component";
import ContactListComponent from "./components/contactList.component";
import ContactinfoListComponent from "./components/contactinfoList.component";
import SubscriberListComponent from "./components/subscriberList.component";
import ReservationListComponent from "./components/reservationList.component";
import AddTestmonialComponent from "./components/addTestmonial.component"
import EditTestmonialComponent from "./components/editTestmonial.component"
import TestimonialListComponent from "./components/testimonialList.component";
import AddOfferComponent from "./components/addOffer.component"
import EditOfferComponent from "./components/editOffer.component"
import OfferListComponent from "./components/offerList.component";
import AddSliderComponent from "./components/addSlider.component"
import AddFaqComponent from "./components/addFaq.component"
import AddPoliciesComponent from "./components/addPolicies.component"
import EditSliderComponent from "./components/editSlider.component"
import SliderListComponent from "./components/sliderList.component";
import EditFaqComponent from "./components/editFaq.component"
import FaqListComponent from "./components/faqList.component";
import EditPoliciesComponent from "./components/editPolicies.component"
import PoliciesListComponent from "./components/policiesList.component";
import EditContactinfoComponent from "./components/editContactinfo.component"
import VillaListComponent from "./components/villaList.component";
import EditVilla from "./components/editVilla.component";
import Policies from "./pages/policies/Policies";
import FAQ from "./pages/policies/FAQ";
import BookingTest from "./pages/policies/BookingTest";
import LoadingSpinner from "./components/spinner/LoadingSpinner";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
      isSliderLoading: false,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }
    
    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
  }
  if (isSliderLoading) {
    // <LoadingSpinner /> 
     return <LoadingSpinner />;
     
   }

  render() {
    const { currentUser, showModeratorBoard, showAdminBoard } = this.state;

    return (
      <div>
        {currentUser ? (
            <nav className="navbar navbar-expand navbar-dark bg-dark ps-3">
            <Link to={"/"} className="navbar-brand">
            Sayulita Casitas
            </Link>
            <div className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link to={"/homelist"} className="nav-link">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/listvilla"} className="nav-link">
                  Villas
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/testimoniallist"} className="nav-link">
                  Testimonial
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/faqlist"} className="nav-link">
                  Faq
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/contactinfolist"} className="nav-link">
                  Contact Details
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/policieslist"} className="nav-link">
                  Policies
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link to={"/offerlist"} className="nav-link">
                  Offer
                </Link>
              </li> */}
              
              <li className="nav-item">
                <Link to={"/contactlist"} className="nav-link">
                  Contact
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/subscriberlist"} className="nav-link">
                  Subscribers
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/reservationlist"} className="nav-link">
                  Reservation
                </Link>
              </li>
              
  
             {/*  {showModeratorBoard && (
                <li className="nav-item">
                  <Link to={"/mod"} className="nav-link">
                    Moderator Board
                  </Link>
                </li>
              )}
  
              {showAdminBoard && (
                <li className="nav-item">
                  <Link to={"/admin"} className="nav-link">
                    Admin Board
                  </Link>
                </li>
              )}
  
              {currentUser && (
                <li className="nav-item">
                  <Link to={"/user"} className="nav-link">
                    User
                  </Link>
                </li>
              )} */}
            </div>
  
            {currentUser ? (
              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                {currentUser.username}
                  {/* <Link to={"/profile"} className="nav-link">
                    {currentUser.username}
                  </Link> */}
                </li>
                <li className="nav-item">
                  <a href="/login" className="nav-link" onClick={this.logOut}>
                    Log Out
                  </a>
                </li>
              </div>
            ) : (
              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link to={"/login"} className="nav-link">
                    Login
                  </Link>
                </li>
  
                <li className="nav-item">
                  <Link to={"/register"} className="nav-link">
                    Sign Up
                  </Link>
                </li>
              </div>
            )}
          </nav>
          ) : (
            <Header />
          )}
          
        
        {/* <nav className="navbar navbar-expand navbar-dark bg-dark">
          <Link to={"/"} className="navbar-brand">
            bezKoder
          </Link>
          <div className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to={"/home"} className="nav-link">
                Home
              </Link>
            </li>

            {showModeratorBoard && (
              <li className="nav-item">
                <Link to={"/mod"} className="nav-link">
                  Moderator Board
                </Link>
              </li>
            )}

            {showAdminBoard && (
              <li className="nav-item">
                <Link to={"/admin"} className="nav-link">
                  Admin Board
                </Link>
              </li>
            )}

            {currentUser && (
              <li className="nav-item">
                <Link to={"/user"} className="nav-link">
                  User
                </Link>
              </li>
            )}
          </div>

          {currentUser ? (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/profile"} className="nav-link">
                  {currentUser.username}
                </Link>
              </li>
              <li className="nav-item">
                <a href="/login" className="nav-link" onClick={this.logOut}>
                  LogOut
                </a>
              </li>
            </div>
          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  Login
                </Link>
              </li>

              <li className="nav-item">
                <Link to={"/register"} className="nav-link">
                  Sign Up
                </Link>
              </li>
            </div>
          )}
        </nav> */}
        

        <div className="">
          <Routes>
            {/* <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} /> */}
            <Route path="/login" element={<Login />} />
            {/* <Route path="/addvilla" element={<AddVillaComponent />} /> */}
            <Route path="/listvilla" element={<VillaListComponent />} />
            <Route path="/editvilla/:id" element={<EditVilla />} />
            <Route path="/addtestmonial" element={<AddTestmonialComponent />} />
            <Route path="/edittestimonial/:id" element={<EditTestmonialComponent />} />
            <Route path="/testimoniallist" element={<TestimonialListComponent />} />
           {/*  <Route path="/addoffer" element={<AddOfferComponent />} />
            <Route path="/editoffer/:id" element={<EditOfferComponent />} />
            <Route path="/offerlist" element={<OfferListComponent />} /> */}
            {/* <Route path="/addslider" element={<AddSliderComponent />} /> */}
            {/* <Route path="/addfaq" element={<AddFaqComponent />} />
            <Route path="/addpolicies" element={<AddPoliciesComponent />} /> */}
            <Route path="/editslider/:id" element={<EditSliderComponent />} />
            <Route path="/homelist" element={<SliderListComponent />} />
            <Route path="/editfaq/:id" element={<EditFaqComponent />} />
            <Route path="/faqlist" element={<FaqListComponent />} />
            <Route path="/editpolicies/:id" element={<EditPoliciesComponent />} />
            <Route path="/policieslist" element={<PoliciesListComponent />} />
            <Route path="/contactlist" element={<ContactListComponent />} />
            <Route path="/contactinfolist" element={<ContactinfoListComponent />} />
            <Route path="/editcontactinfo/:id" element={<EditContactinfoComponent />} />
            <Route path="/subscriberlist" element={<SubscriberListComponent />} />
            <Route path="/reservationlist" element={<ReservationListComponent />} />
            {/* <Route path="/register" element={<Register />} /> */}
            <Route path="/profile" element={<Profile />} />
            {/* <Route path="/user" element={<BoardUser />} />
            <Route path="/mod" element={<BoardModerator />} />
            <Route path="/admin" element={<BoardAdmin />} /> */}
           {/*  <Route path="/offers" element={<Offers />} /> */}
            <Route path="/" element={<Home />} />
            <Route path="/units" element={<VillaList />} />
            <Route path="/villas/:id" element={<VillaRoom />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/reservation" element={<Reservation />} />
            <Route path="/policies" element={<Policies />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/booking-demo" element={<BookingTest />} />
          </Routes>
        </div>
        {/* {!currentUser && (
              <Footer />
            )} */}
        

        {/* <AuthVerify logOut={this.logOut}/> */}
        
      </div>
    );
  }
}

export default App;
