import React, { useState, useEffect, Component } from 'react'
import PageTop from './title/PageTop'

//import './Contact.css';
import SliderService from '../services/slider.service';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import Logo from '../assets/images/logo.svg';
//import './Contact.css';

import AuthService from "../services/auth.service";

import { withRouter } from '../common/with-router';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from 'axios';
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
var FormData = require('form-data');




export default function EditSlider() {
  const state = useLocation().state;
    console.log("state ====== ", state)
    const [id, setId] = useState(state?.id || "");
    const [title, setTitle] = useState(state?.title || "");
    const [offer_image, setOfferImage] = useState(state?.offer_image || "");
    const [user, setUser] = useState("{redirect: null, userReady: false, currentUser: { username: '' }}");
    const [offer_pre_image, setOfferPreImage] = useState("");
    const [status, setStatus] = useState(undefined);
    const [offer_image_exist, setOfferImageExist] = useState(state?.offer_image || "");

    const navigate = useNavigate();

    useEffect(() => {
      const currentUser = AuthService.getCurrentUser();
      if (!currentUser) {
      setUser({ redirect: "/home" });
      }  else {
        setUser({ currentUser: currentUser, userReady: true })
      }
    }, []);

    const upload = async () => {
      try {
        const formData = new FormData();
        formData.append("file", offer_image);
        const res = await axios.post("http://sayulitacasitas.com/api/upload", formData);
        return res.data;
      } catch (err) {
        console.log(err);
      }
    };

    function handleChange(e) {
      console.log(e.target.files);
      setOfferImage(e.target.files[0])
      setOfferPreImage(URL.createObjectURL(e.target.files[0]));
      setOfferImageExist(null);
  } 
  const resetSlider = () => {
    setTitle("");  ; 
  }
  
    const handleSubmit= async (e) => {
        e.preventDefault();
        //const imgUrl =  await upload();
        let imgUrl ="";
        console.log("offer_image_exist value == ", offer_image_exist)
        if(offer_image_exist){
         // offer_image = offer_image_exist;
         var data = {
          title: title,
          offer_image:  offer_image_exist,
         };
        }else{
          imgUrl =  await upload();
          var data = {
            title: title,
            offer_image:  imgUrl,
           };
        }
        /* var data = {
          title: title,
          offer_image: offer_image ? imgUrl : offer_image_exist,
         }; */
          
              SliderService.update(id, data)
          .then(response => {
          console.log(response.data);
          resetSlider();
          setStatus({ type: 'success' });
          })
          .catch(e => {
            console.log(e);
            setStatus({ type: 'error', e });
          });
          setTimeout(function() {
            setStatus("");
           window.location.href = "/homelist";
           }, 2000);
          }
          if (user.redirect) {
            return <Navigate to={user.redirect} />
          }
  
     

  return (
    <>
    {(user.userReady) ?
    <div>
        <PageTop PageTitle="Edit Home Slider"/>
        <div className="container my-5 bg_light">
            <div className="row g-0">
                <div className="col-md-12">
                    <form onSubmit={e => {handleSubmit(e)}} className="px-3 py-3" >
                        <h4 className="mb-4">Edit Home Slider</h4>
                        <div className="row g-2">
                        <div className="col-md-6">
                              <input className="form-control mb-2" type="file" id="offer_image" name="" onChange={handleChange} />
                              </div>
                              <div className="col-md-12">
                                {offer_image && <div className="image" ><img className="img-fluid" src={offer_pre_image} /></div>} 
                              </div>
                              <div className="col-md-12">
                                {/* <img className='img-fluid' src={`../upload/${offer_image}`} alt=''/> */}
                                {offer_image_exist && <div className="" ><img className="img-fluid"  src={`../upload/${offer_image_exist}`} /></div>} 
                              </div>
                            <div className="col-md-12">
                             <ReactQuill required style={{minHeight:"200px"}} className="form-control mb-2 editor" theme="snow" value={title} onChange={setTitle} />
                             </div>
                             <div className="col-md-12">
                                <div className='d-flex gap-3'>
                                  <button type="submit" className="btn btn-primary ">Submit</button>
                                  <Link to='/homelist' className="btn btn-secondary ">Cancel</Link>
                                </div>
                            </div>
                            <div className="col-md-12 my-3">
                            <div className="form-check mt-2">
                                    {status?.type === 'success' && <p>Your information was successfully submitted!</p>}
                                    {status?.type === 'error' && (
                                      <p>Error in Form Submission please try again!!!</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                
            </div>
        </div>
        <div className="my-5 text-center pb-4 text-capitalize"><h2>Enjoy a superb Sayulita vacation!</h2></div>
    </div>: null}
    </>
  )
}
