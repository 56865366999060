import React, { useState, useEffect, Component } from 'react'
import PageTop from './title/PageTop'
import ReservationService from '../services/reservation.service';
import AuthService from "../services/auth.service";
import "react-quill/dist/quill.snow.css";
import { Navigate } from "react-router-dom";


export default function ContactList() {
  const [APIData, setAPIData] = useState([]);
  const [user, setUser] = useState("{redirect: null, userReady: false, currentUser: { username: '' }}");
  
    useEffect(() => {
      const currentUser = AuthService.getCurrentUser();
      if (!currentUser) {
      setUser({ redirect: "/" });
      }  else {
        setUser({ currentUser: currentUser, userReady: true })
      }
      ReservationService.getAllReservation().then(
        response => {
            setAPIData(response.data);
        },
        error => {
            console.log(error.data)
            //setAPIData(response.data);
        }
      );
    }, []);
    
      if (user.redirect) {
        return <Navigate to={user.redirect} />
      }
  
     

  return (
    <>
    {(user.userReady) ?
    <div>
        <PageTop PageTitle="Reservation List"/>
        
        <div className="container my-5 contact_wrap1 bg_light" >
            <div className="row g-0">
                <div className="col-md-12">
                        <h4 className="mb-4 mt-4">Reservation List</h4>
                        <div className='table-responsive'>
                        <table className="table table-hover table-striped">
                        <thead className="thead-dark">
                <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Unit</th>
                    <th>Villa Type</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Adult</th>
                    <th>Children</th>
                    <th>Message</th>
                </tr>
            </thead>
            <tbody>
                { APIData.length > 0 ? (
                    APIData.map(contact => {
                        const {id, fname, lname, email, phone, villa, unit_type, fromdate, todate, adult, children, message} = contact;
                        return (
                            <tr key={id}>
                                <td>{fname}</td>
                                <td>{lname}</td>
                                <td><a href={`mailto:${email}`}>{email}</a></td>
                                <td><a href={`tel:${phone}`}>{phone}</a></td>
                                <td>{villa}</td>
                                <td>{unit_type}</td>
                                <td>{fromdate}</td>
                                <td>{todate}</td>
                                <td>{adult}</td>
                                <td>{children}</td>
                                <td>{message}</td>
                                </tr>
                        )
                    })
                ) : (
                    <tr>
                        <td colSpan={7}>No Reservation list found</td>
                    </tr>
                )   
                }
            </tbody>
                        </table>
                        </div>
                </div>
            </div>
        </div>
     </div>: null}
    </>
  )
}
