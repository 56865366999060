import React, { useState, useEffect, Component } from 'react'
import PageTop from './title/PageTop'
import SubscriberService from '../services/subscriber.service';
import AuthService from "../services/auth.service";
import "react-quill/dist/quill.snow.css";
import { Navigate } from "react-router-dom";


export default function SubscriberList() {
  const [APIData, setAPIData] = useState([]);
  const [user, setUser] = useState("{redirect: null, userReady: false, currentUser: { username: '' }}");
  
    useEffect(() => {
      const currentUser = AuthService.getCurrentUser();
      if (!currentUser) {
      setUser({ redirect: "/" });
      }  else {
        setUser({ currentUser: currentUser, userReady: true })
      }
      SubscriberService.getAllSubscriber().then(
        response => {
            setAPIData(response.data);
        },
        error => {
            console.log(error.data)
            //setAPIData(response.data);
        }
      );
    }, []);
    
      if (user.redirect) {
        return <Navigate to={user.redirect} />
      }
  
     

  return (
    <>
    {(user.userReady) ?
    <div>
        <PageTop PageTitle="Subscriber List"/>
        
        <div className="container my-5 contact_wrap1 bg_light" >
            <div className="row g-0">
                <div className="col-md-12">
                        <h4 className="mb-4 mt-4">Subscriber List</h4>
                        <table className="table table-hover table-striped">
                        <thead className="thead-dark">
                <tr>
                    <th> News Letter Subscriber Email</th>
                    </tr>
            </thead>
            <tbody>
                { APIData.length > 0 ? (
                    APIData.map(subscriber => {
                        const {id, email} = subscriber;
                        return (
                            <tr key={id}>
                                <td><a href={`mailto:${email}`}>{email}</a></td>
                                </tr>
                        )
                    })
                ) : (
                    <tr>
                        <td colSpan={3}>No News Letter Subscriber found</td>
                    </tr>
                )   
                }
            </tbody>
                        </table>
                </div>
            </div>
        </div>
     </div>: null}
    </>
  )
}
