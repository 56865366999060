import React, {useState, useRef} from 'react';
import PageTop from '../../components/title/PageTop';
import './Reservation.css';
import ReservationService from '../../services/reservation.service';
import { useLocation } from "react-router-dom";
import Footer from '../../components/footer/Footer';

export default function Reservation() {
    const ref = useRef();
    const location = useLocation();
console.log(location);
console.log("location.state details",location.state);
    const state = location.state;
    const [fname, setfName] = useState("");
    const [lname, setlName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [villa, setVilla] = useState(state?.villa || "");
    const [unit_type, setUnitType] = useState(state?.unit || "");
    const [fromdate, setFromDate] = useState("");
    const [todate, setToDate] = useState("");
    const [adult, setAdult] = useState("");
    const [children, setChildren] = useState("");
    const [message, setMessage] = useState("");
    const [cities, setCities] = useState([]);
    const [status, setStatus] = useState(undefined);
    const resetReservation = () => {
        setfName("");
        setlName("");
        setEmail("");
        setPhone("");
        setFromDate("");
        setToDate("");
        setAdult("");
        setChildren("");
        setMessage("");
        setUnitType("");
        setVilla("");

      }
    var data = {
        fname: fname,
        lname: lname,
        email: email,
        phone:phone,
        villa: villa,
        unit_type: unit_type,
        fromdate:fromdate,
        todate:todate,
        adult:adult,
        children:children,
        message:message
      };
      

      const countries = {
        "Casa Pata Salada": ["Casita #1 Ocean View", "Casita #2 Garden View", "Casita #3 Garden View", "Casita #4 Ocean View", "Casita #5 Ocean View", "Casita #6 Ocean View", "Casita #7 Ocean View", "Casita #8 Ocean View", "Casita #9 Ocean View"],
        "La Esquina" : ["LARGE LE Room (LE2)", "LARGE LE Room (LE3)", "LARGE LE Room (LE6)", "LARGE LE Room (LE7)", "SMALL LE Room (LE1)", "SMALL LE Room (LE4)", "SMALL LE Room (LE5)", "SMALL LE Room (LE8)", "The Penthouse Room (LE)"]

      };

      /* const countryList = Object.keys(countries).map(key => ({
        name: key
      }));
    
      function handleCountrySelect(e) {
        const countrySel = e.target.value;
        const citiesSel = countrySel !== "" ? countries[countrySel] : "";
        setSelectedCountry(countrySel);
        setCities(citiesSel);
        setSelectedCity("");
      }
    
      function handleCitySelect(e) {
        const citiesSel = e.target.value;
        setSelectedCity(citiesSel);
      } */
console.log("villa value =========",villa)
console.log("unit_type value =========",unit_type)
    const handleSubmit= (e) => {
        e.preventDefault();
        // ???
        console.log("data",data)
        
        ReservationService.create(data)
      .then(response => {
        
        console.log(response.data);
        resetReservation();
        setStatus({ type: 'success' });
      })
      .catch(e => {
        console.log(e);
        setStatus({ type: 'error', e });
      });
      setTimeout(function() {
        setStatus("");
       }, 2000);
      }
      /* if(location.state){
      //console.log("location.state.villa",location.state.villa)
      //console.log("location.state.unit",location.state.unit)
      //setVilla(location.state.villa)
      //setSelectedCountry(location.state.villa);
      setUnitType(location.state.unit);
      } */

  return (
    <div>
        <PageTop PageTitle="Make a Reservation Inquiry"/>
        <div className="container my-5 contact_wrap">
            <div className="row g-0">
                <div className="col-md-7 mx-auto">
                <form onSubmit={e => {handleSubmit(e)}} className="contact_form">
                        <h4 className="mb-3">Guest Information</h4>
                        <div className="row g-2 mb-3">
                            <div className="col-md-6">
                                <input type="text" required maxLength= "20" className="form-control" placeholder="First Name" value={fname} name="fname" onChange={e => setfName(e.target.value)}/>
                            </div>
                            <div className="col-md-6">
                                <input type="text" required maxLength= "20" className="form-control" placeholder="Last Name" value={lname} name="lname" onChange={e => setlName(e.target.value)}/>
                            </div>
                            <div className="col-md-6">
                                <input type="email" required maxLength= "50" className="form-control" placeholder="Email Address" value={email} name="email" onChange={e => setEmail(e.target.value)}/>
                            </div>
                            <div className="col-md-6">
                                <input type="text" required pattern= "[0-9]+" minLength = "10" maxLength= "15" className="form-control" placeholder="Phone" value={phone} name="phone" onChange={e => setPhone(e.target.value)}/>
                            </div>
                        </div>
                        <h4 className="mb-3">Booking Information</h4>
                        
                        <div className="row g-2">
                        <div className="col-md-6">
                        <select required className="form-select mb-2" value={villa} name="villa" onChange={e => setVilla(e.target.value)}>
                                    <option value="" >Property Name</option>
                                    <option value="Casa Pata Salada">Casa Pata Salada</option>
                                    <option value="La Esquina">La Esquina</option>
                                </select> 
                         </div>
                         <div className="col-md-6">
                          
                         {villa ==="Casa Pata Salada" ? (
         <select required className="form-select mb-2" value={unit_type} name="unit_type" onChange={e => setUnitType(e.target.value)}>
            <option value="" >Unit Name</option>
            <option value="Casita #1 Ocean View">Casita #1 Ocean View</option>
            <option value="Casita #2 Garden View">Casita #2 Garden View</option>
            <option value="Casita #3 Garden View">Casita #3 Garden View</option>
            <option value="Casita #4 Ocean View">Casita #4 Ocean View</option>
            <option value="Casita #5 Ocean View">Casita #5 Ocean View</option>
            <option value="Casita #6 Ocean View">Casita #6 Ocean View</option>
            <option value="Casita #7 Ocean View">Casita #7 Ocean View</option>
            <option value="Casita #8 Ocean View">Casita #8 Ocean View</option>
            <option value="Casita #9 Ocean View">Casita #9 Ocean View</option>
        </select> 
      ) : (
        <select required className="form-select mb-2" value={unit_type} name="unit_type" onChange={e => setUnitType(e.target.value)}>
            <option value="" >Unit Name</option>
            <option value="LARGE LE Room (LE2)">LARGE LE Room (LE2)</option>
            <option value="LARGE LE Room (LE3)">LARGE LE Room (LE3)</option>
            <option value="LARGE LE Room (LE6)">LARGE LE Room (LE6)</option>
            <option value="LARGE LE Room (LE7)">LARGE LE Room (LE7)</option>
            <option value="SMALL LE Room (LE1)">SMALL LE Room (LE1)</option>
            <option value="SMALL LE Room (LE4)">SMALL LE Room (LE4)</option>
            <option value="SMALL LE Room (LE5)">SMALL LE Room (LE5)</option>
            <option value="SMALL LE Room (LE8)">SMALL LE Room (LE8)</option>
            <option value="The Penthouse Room (LE)">The Penthouse Room (LE)</option>
        </select> 
      )}
                             </div>
                            {/* <div className="col-md-6">
                                <select className="form-select" value={villa} name="villa" onChange={e => setVilla(e.target.value)}>
                                    <option value="0" disabled>Select Villa</option>
                                    <option value="cps_villa">Casa Pata Salada</option>
                                    <option value="le_villa">La Esquina</option>
                                </select>
                            </div> */}
                            {/* <div className="col-md-6">
                                <select className="form-select" required
                                name="Countries"
                                onChange={e => handleCountrySelect(e)}
                                value={selectedCounty}
                                >
                                <option value="">Select Villa</option>
                                {countryList.map((country, key) => (
                                    <option key={key} value={country.name}>
                                    {country.name}
                                    </option>
                                ))}
                                </select>
                                </div>
                                <div className="col-md-6">
                                <select  required className="form-select" name="Cities" onChange={e => handleCitySelect(e)} value={selectedCity}>
                                <option value="">Select Unit Type</option>
                                {cities.map((city, key) => (
                                    <option key={key} value={city}>
                                    {city}
                                    </option>
                                ))}
                                </select>
                                </div> */}
                            {/* <div className="col-md-6">
                                <select className="form-select" value={unit_type} name="unit_type" onChange={e => setUnitType(e.target.value)}>
                                    <option value="0" disabled>Select Unit Type</option>
                                    <option value="ls">Large Studio</option>
                                    <option value="sm">Small Studio</option>
                                </select>
                            </div> */}
                            <div className="col-md-12">
                                <div className="input-daterange row gx-2">
                                    <div className="col-sm-6">
                                        <input type="text" onFocus={(e) => (e.target.type = "date")} onBlur={(e) => (e.target.type = "date")}  min={new Date().toISOString().split('T')[0]} required className="form-control down_arrow input_icon text-start"  placeholder="Arrive" value={fromdate} name="fromdate" onChange={e => setFromDate(e.target.value)}/>
                                    </div>
                                    <div className="col-sm-6">
                                        <input type="text" onFocus={(e) => (e.target.type = "date")} disabled={fromdate === "" ? true: false}
                                         min={fromdate ? new Date(fromdate).toISOString().split("T")[0]: ""} required className="form-control down_arrow input_icon text-start"  placeholder="Depart" value={todate} name="todate" onChange={e => setToDate(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="row gx-2">
                                    <div className="col-sm-6 mb-sm-2">
                                        <select required className="form-select" value={adult} name="adult" onChange={e => setAdult(e.target.value)}>
                                            <option value="">Adults</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-6 mb-sm-2">
                                        <select  className="form-select" value={children} name="children" onChange={e => setChildren(e.target.value)}>
                                            <option value="">Children</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <textarea required maxLength= "100" className="form-control mb-2" cols="10" rows="4" placeholder="Message for owner" value={message} name="message" onChange={e => setMessage(e.target.value)}></textarea>
                            </div>
                            <div className="col-md-12">
                                <button type="submit" className="btn btn-primary w-100">Submit</button>
                            </div>
                        </div>
                        {status?.type === 'success' && <div class="alert alert-success mt-3" role="alert">
                        <h5>Thank you for getting in touch!</h5>
                        <p>We appreciate you contacting us. One of our colleagues will get back in touch with you soon! <b>Have a great day!</b></p>
                       </div>

}
      {status?.type === 'error' && (
        <p>Error in Form Submission please try again!!!</p>
      )}
                        
                    </form>
                </div>
            </div>
        </div>
        <div className="my-5 text-center pb-4 text-capitalize"><h2>Enjoy a superb Sayulita vacation!</h2></div>
        <Footer />
    </div>
  )
}
