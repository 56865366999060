import axios from 'axios';


const API_URL = 'http://sayulitacasitas.com/api/';


class SliderService {
  getAllSlider() {
    return axios.get(API_URL + 'sliders');
  }

  get(id) {
    return axios.get(API_URL + `sliders/${id}`);
  }

  create(data) {
    return axios.post(API_URL + 'sliders', data);
  }
  
  update(id, data) {
    return axios.put(API_URL + `sliders/${id}`, data);
  }

  delete(id) {
    return axios.get(API_URL + `sliders/${id}`);
  }

  deleteAll() {
    return axios.get(API_URL + 'sliders');
  }

  findByTitle(title) {
    return axios.get(API_URL + `sliders?title=${title}`);
  }
}

  
export default new SliderService();
